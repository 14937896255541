<template>
  <div id="Myfont" class="h-screen bg text-white">
    <div class="flex justify-center">
      <div class="my-24 py-3 bg-darkcard shadow-xs p-3 rounded-lg">
        <div class="p-2">
          <div class="flex justify-center">
            <img class="h-28" src="../../assets/person.svg" alt="" />
          </div>
          <div class="flex justify-center space-y-4 font-thin">
            <p class="text-lg">Dev-team login</p>
          </div>
          <form
            @submit.prevent="onSubmit"
            class="mt-4 space-y-3 max-w-4xl w-72"
          >
            <BaseInput
              @keypress.enter="onSubmit()"
              placeholder="Email"
              v-model.trim="formdata.email"
              @blur="$v.formdata.email.$touch()"
              inputClass="bg-darkbg outline-none w-full text-sm p-3 rounded"
            />
            <div v-if="$v.formdata.email.$error">
              <p
                class="text-xs text-red-600"
                v-if="!$v.formdata.email.required"
                id="error_label"
              >
                email is required!
              </p>
            </div>
            <div v-if="$v.formdata.email.$error">
              <p
                class="text-xs text-red-600"
                v-if="!$v.formdata.email.numeric"
                id="error_label"
              >
                invalid email!
              </p>
            </div>
            <div>
              <label for="password" class="sr-only">Password</label>

              <div>
                <BaseInput
                  :passwordInput="true"
                  inputClass="bg-darkbg outline-none text-sm w-full p-3 rounded"
                  placeholder="Password"
                  @blur="$v.formdata.password.$touch()"
                  v-model.trim="formdata.password"
                  @keypress.enter="onSubmit()"
                />
                <div v-if="$v.formdata.password.$error">
                  <p
                    class="text-xs text-red-600"
                    v-if="!$v.formdata.password.required"
                    id="error_label"
                  >
                    password is required!
                  </p>
                </div>
              </div>
            </div>
          </form>
          <!-- <div class="flex justify-end space-x-6">
            <div>
              <a
                @click="$router.push({ name: 'forgot-password' })"
                id="cursorHand"
                class="inline-block align-baseline underline text-blue-400 text-xs cursor-pointer"
              >
                forgot password!
              </a>
            </div>
          </div> -->
          <div class="flex justify-center mt-5">
            <button
              :disabled="loginRequestSent"
              type="submit"
              Class="bg-text flex justify-center space-x-3 py-3 rounded-lg w-full text-white"
              @click="onSubmit()"
            >
              <svg
                v-if="loginRequestSent"
                class="h-6 w-6 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p v-if="!loginRequestSent">Login</p>
            </button>
          </div>
          <p v-if="error" class="text-xs text-red-700">
            please fill out the forms correctly!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
export default {
  components: {},
  data() {
    return {
      error: false,
      loginRequestSent: false,
      show: false,
      status: false,
      formdata: {
        email: "",
        password: "",
      },
    };
  },

  validations: {
    formdata: {
      email: { required, email },
      password: { required },
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loginRequestSent = true;
        this.$store
          .dispatch("Authentication/signIn", this.formdata)
          .then((response) => {
            // console.log("response", response);
            if (response && !response.error) {
              this.$router.push({
                name: "dashboard",
              });
              this.loginRequestSent = false;
            } else {
              this.loginRequestSent = false;
            }
          });
      } else {
        // console.log("error");
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 5000);
      }
    },
    login() {
      this.$store
        .dispatch("Authentication/signIn", this.formdata)
        .then((response) => {
          if (response) {
            this.$router.push({
              name: "dashboard",
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.bg {
  background-image: url("../../assets/loginBg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
