/* eslint-disable */
// import Axios from "axios";
// import Vue from "vue";
import CryptoJS from "crypto-js";
const key = "itain'tbeingalone";
import AxiosService from "../../services/AxiosServices";
import AuthenticationServices from "@/services/AuthenticationServices";
export const namespaced = true;
import router from "@/router";
export const state = {
  userInfo: [],
  userToken: null,
  userRole: "",
  nightMode: "",
  me: [],
};

export const mutations = {
  //Signin
  USER_TOKEN(state, token) {
    state.userToken = token;
  },
  USER_INFO(state, userInfo) {
    state.userInfo = userInfo;
  },
};
export const actions = {
  // logOut() {
  //   const routeToHome = () => {
  //     localStorage.removeItem("name");
  //     localStorage.removeItem("token");
  //     localStorage.removeItem("userId");
  //     localStorage.removeItem("userRole");
  //     this.$router.push({
  //       name: "log-in",
  //     });
  //   };
  //   window.setTimeout(routeToHome, 1000);
  // },
  //signup
  signUp({ commit, dispatch }, payload) {
    // console.log(payload)
    return AxiosService.postSignup(payload)
      .then((res) => {
        const notification = {
          type: "successs",
          message:
            "Your account has been created!,please now login to continue",
        };
        dispatch("notification/add", notification, { root: true });
        return res;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          }
        }
      });
  },
  updateMyProfile({ dispatch }, payload) {
    // console.log("this", payload);
    return AxiosService.updateMyProfile(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message: "You have signed in successsfully",
        };
        dispatch("notification/add", notification, { root: true });
        return true;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          }
        }
      });
  },
  updateMyPassword({ dispatch }, formdata) {
    // console.log("this", formdata);
    return AxiosService.updateMyPassword(formdata)
      .then((res) => {
        const notification = {
          type: "success",
          message: "Password updated successsfully",
        };
        dispatch("notification/add", notification, { root: true });
        return true;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          }
        }
      });
  },
  //signin
  signIn({ state, commit, dispatch }, payload) {
    // console.log(payload);
    return AuthenticationServices.devTeamLogin(payload)
      .then((res) => {
        let user = {
          id: res.data.user._id,
          agentName: res.data.user.agentName,
          employeeName: res.data.user.employeeName,
          email: res.data.user.email,
          role: res.data.user.role,
          pic: res.data.user.photos,
          // mode: res.data.user.nightMode,
          phoneNumber: res.data.user.phoneNumber,
        };
        const encryptedToken = CryptoJS.AES.encrypt(
          res.data.token,
          key
        ).toString();
        localStorage.setItem("encryptedDevTeamToken", encryptedToken);
        localStorage.setItem("user", JSON.stringify(user));
        // localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(user));
        commit("USER_INFO", res.data.user);
        commit("USER_TOKEN", res.data.token);
        state.nightMode = res.data.user.nightMode;
        // console.log("night Mode", state.nightMode);
        const notification = {
          type: "success",
          message: "You have signed in successsfully",
        };
        dispatch("notification/add", notification, { root: true });
        return res;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              // router.push("/");
              const notification = {
                type: "error",
                message: "Wrong Password and email combination!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              // console.log("obvehee: ");
              const notification = {
                type: "error",
                message: error.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
    // return false
  },
  getMe({ state, dispatch }) {
    return AxiosService.getMe()
      .then((res) => {
        state.me = res.data;
        // console.log("res: ", res.data);
        return true;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          }
        }
      });
  },
  ResetPassword({ commit, dispatch }, payload) {
    // console.log(payload);
    return AuthenticationServices.ResetPassword(payload)
      .then((res) => {
        // console.log("res status sts: ", res.data.status);
        // console.log("res  sts: ", res);
        if (res.data.status === "successs") {
          localStorage.clear();
          let user = {
            id: res.data.user._id,
            employeeName: res.data.user.employeeName,
            email: res.data.user.email,
            role: res.data.user.role,
            pic: res.data.user.photos,
            phoneNumber: res.data.user.phoneNumber,
          };
          const encryptedToken = CryptoJS.AES.encrypt(
            res.data.token,
            key
          ).toString();
          localStorage.setItem("encryptedDevTeamToken", encryptedToken);
          // localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(user));
          commit("USER_INFO", res.data.user);
          commit("USER_TOKEN", res.data.token);
          const notification = {
            type: "success",
            message:
              "you have successsfully rested your password and logged in!",
          };
          dispatch("notification/add", notification, { root: true });
          return true;
        } else if (res.data.status === "activate") {
          const notification = {
            type: "error",
            message:
              "Your account is not active please contact your branch manager!!!",
          };
          dispatch("notification/add", notification, { root: true });
          return false;
        }
      })
      .catch((error) => {
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              // router.push("/");
              const notification = {
                type: "error",
                message: "Unauthorized Navigation",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  forgetPassword({ commit, dispatch }, payload) {
    // console.log(payload);
    return AuthenticationServices.forgetPassword(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message:
            "we have sent password reset code Please check your email account!",
        };
        dispatch("notification/add", notification, { root: true });
        return true;
      })
      .catch((error) => {
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              // router.push("/");
              const notification = {
                type: "error",
                message: "This user doesn't exist!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  ResetPasswordByLink({ commit, dispatch }, { token, formdata }) {
    return AuthenticationServices.ResetPasswordByLink({ token, formdata })
      .then((res) => {
        // console.log("res status sts: ", res.data.status);
        // console.log("res  sts: ", res);
        if (res.data.status === "successs") {
          localStorage.clear();
          let user = {
            id: res.data.user._id,
            employeeName: res.data.user.employeeName,
            email: res.data.user.email,
            role: res.data.user.role,
            pic: res.data.user.photos,
            phoneNumber: res.data.user.phoneNumber,
          };
          // localStorage.setItem("token", res.data.token);
          const encryptedToken = CryptoJS.AES.encrypt(
            res.data.token,
            key
          ).toString();
          localStorage.setItem("encryptedDevTeamToken", encryptedToken);
          localStorage.setItem("user", JSON.stringify(user));
          commit("USER_INFO", res.data.user);
          commit("USER_TOKEN", res.data.token);
          const notification = {
            type: "success",
            message:
              "you have successsfully rested your password and logged in!",
          };
          dispatch("notification/add", notification, { root: true });
          return true;
        } else if (res.data.status === "activate") {
          const notification = {
            type: "error",
            message:
              "Your account is not active please contact your branch manager!!!",
          };
          dispatch("notification/add", notification, { root: true });
          return false;
        }
      })
      .catch((error) => {
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message: "Unauthorized Navigation",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  logOut({ dispatch }) {
    const notification = {
      type: "logout",
      message: "You have signed off successsfully",
    };
    dispatch("notification/add", notification, { root: true });
  },
};
export const getters = {};

export default {};
