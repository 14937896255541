<template>
  <div id="app" class="bg-darkbg h-screen">
    <transition name="slide">
      <notification-container class="z-50" />
    </transition>
    <router-view />
  </div>
</template>
<script>
import NotificationContainer from "./components/Notifications/NotificationContainer.vue";
export default {
  components: { NotificationContainer },
};
</script>

<style>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
.moodal {
  position: fixed;
  z-index: 99;
  background-color: #2c2c2e;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 98;
  background-color: rgba(0, 0, 0, 0.6);
}
.modal-enter-active,
.modal-leave-active {
  transition: all 0.5s ease;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.5);
}
</style>
