/* eslint-disable */
import Vue from "vue";
import AxiosService from "../../services/AxiosServices";
import router from "@/router";
// import { Socket } from "socket.io";

export const namespaced = true;
export const state = {
  printer_off: true,
  allTickets: 0,
  tickets: [],
  print: [],
  ticket: {
    game: [],
  },
  singleTicket: [],
  searchResult: [],
  totalPrice: 0,
};
export const mutations = {
  SET_CREATED_TICKET(state, payload) {
    state.print = payload;
  },
};

export const actions = {
  startTime({ state }, startTime) {
    if (startTime) {
      state.start_time = startTime;
      return true;
    }
  },
  endTime({ state }, endTime) {
    if (endTime) {
      state.end_time = endTime;
      return true;
    }
  },
  addGame({ state }, payload) {
    if (payload) {
      state.ticket.game.push(payload);
      state.totalPrice = state.ticket.game.reduce(function (sum, price) {
        return sum + price.placedMoney;
      }, 0);
      return true;
    }
  },
  // get Requests
  getAllTickets({ dispatch, state }, { perPage, page }) {
    return AxiosService.getAllTickets(perPage, page)
      .then((res) => {
        // state.allTickets = res.data.doc;
        state.tickets = res.data.doc;
        // console.log("Tickets: ", res.data.doc);
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  getCountAllTickets({ state }) {
    AxiosService.getCountAllTickets().then((res) => {
      state.allTickets = res.data.result;
      // console.log("Tickets: ", res.data.result);
    });
  },
  getTicketById({ dispatch, state }, id) {
    AxiosService.getTicketById(id)
      .then((res) => {
        state.singleTicket = res.data;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  searchTicket({ disaptch, state }, search) {
    AxiosService.searchTicket(search)
      .then((res) => {
        let Result = res.data.bet;
        state.searchResult.push({ disaptch, Result });
        // console.log("search", res.data);
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  getAllTicketsByDate({ dispatch, state }, date) {
    AxiosService.getAllTicketsByDate(date)
      .then((res) => {
        state.tickets = res.data;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  // Post Request
  createTicket({ disaptch, state, commit }, gameId) {
    return AxiosService.createTicket({
      disaptch,
      gameId: gameId,
      game: state.ticket.game,
    })
      .then((res) => {
        state.print = [];
        // state.print = res.data;
        state.tickets = [];
        state.ticket.game = [];
        state.totalPrice = 0;
        commit("SET_CREATED_TICKET", [res.data.doc]);
        // console.log("create Ticket: ", res.data);
        const notification = {
          type: "success",
          message: "bet created successsfully!",
        };
        dispatch("notification/add", notification, { root: true });
        return true;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
};
export const getters = {};
