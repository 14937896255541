<template>
  <div class="text-white bg-darkcard my-1">
    <div class="">
      <div class="bg-darkcard">
        <div class="flex justify-between text-start bg-bg-darkcar p-1 pr-5">
          <div class="">Branch Details</div>
          <div class="">
            <BaseSelect
              class="space-x-3"
              @input="setBranch()"
              v-model="selectedAgent"
              selectClass="w-full p-1 bg-darkbg text-base"
              optionClass="w-full space-x-3 font-thin text-lg"
              :options="returnBranchName"
            />
            <!-- {{ returnBranchName }} -->
          </div>
          <!-- {{ selectedAgent }} -->
        </div>
      </div>
      <div class="flex">
        <div class="w-12" style="height: calc(100vh - 90px)">
          <div class="bg-darkcard mr-1 h-full mb-2 px-1">
            <BranchesSideBar />
          </div>
        </div>
        <div v-if="show" class="w-full mx-1 mb-1 bg-darkbg p-3 m-2">
          <branch-dashboard :id="id" v-if="sideBarStatus === 'dashboard'" />
          <branch-tickets :id="id" v-if="sideBarStatus === 'tickets'" />
          <branch-employees :id="id" v-if="sideBarStatus === 'employees'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BranchesSideBar from "@/components/children/BranchesSideBar.vue";
import BranchDashboard from "@/components/children/BranchDashboard.vue";
import BranchTickets from "@/components/children/BranchTickets.vue";
import { mapGetters, mapState } from "vuex";
import BranchEmployees from "../../components/children/BranchEmployees.vue";

export default {
  components: {
    BranchesSideBar,
    BranchDashboard,
    BranchTickets,
    BranchEmployees,
  },
  data() {
    return {
      res: [],
      show: false,
      selectedAgent: "",
      Branches: [],
      listOfBranches: [],
      activeId: 0,
      id: 0,
    };
  },
  created() {
    this.activeId = localStorage.getItem("activeAgent");
    // <!-- console.log("aA: ", this.activeId); -->
    this.$store
      .dispatch("branches/getAllBranchesForDetail")
      .then((response) => {
        let index;
        if (this.activeId !== null) {
          // console.log("Local: ", this.activeId);
          // console.log("aA: ", this.activeId);
          this.id = this.activeId;
          this.Branches = response;
          index = response.findIndex((element) => {
            return String(element.id) === String(this.activeId);
          });
          if (index !== -1) {
            // console.log("index: ", index);
            this.selectedAgent = response[index].agentName;
            this.show = true;
          } else {
            // console.log("No branch found with the provided activeId.");
          }
        } else {
          if (response.length > 0) {
            // console.log("no in local: ", index);
            this.id = response[0].id;
            this.selectedAgent = response[0].agentName;
            localStorage.setItem("activeAgent", response[0].id);
            // console.log(
            //   "no in local setting: ",
            //   localStorage.getItem("activeAgent")
            // );
            this.Branches = response;
            this.show = true;
            // console.log("here");
          } else {
            // console.log("No branches found.");
          }
        }
      })
      .catch((error) => {
        console.log("An error occurred while fetching branches:", error);
      });
  },
  computed: {
    ...mapState("branches", ["BranchesForDetail"]),
    ...mapState("branches", ["singleBranch"]),
    ...mapGetters("status", ["sideBarStatus"]),
    returnBranchName() {
      if (this.Branches) {
        let agents = [];
        this.listOfBranches = [];
        this.Branches.forEach((element) => {
          let name = element.agentName;
          let id = element._id;
          agents.push(name);
          this.listOfBranches.push({ name, id });
          // console.log("list: ", this.listOfBranches);
        });
        return agents;
      }
    },
  },
  methods: {
    setBranch() {
      const index = this.Branches.findIndex((element) => {
        return String(element.agentName) === String(this.selectedAgent);
      });
      this.$store.state.branches.BranchesForDetail.aId =
        this.Branches[index].id;
      localStorage.setItem("activeAgent", this.Branches[index].id);
      this.$store.state.branches.BranchesForDetail.activeAgent =
        this.Branches[index].agentName;
      this.id = this.Branches[index].id;
      this.activeId = this.id;
    },
  },
};
</script>

<style></style>
