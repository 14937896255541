<template>
  <div id="Myfont" class="h-screen">
    <div class="flex justify-center">
      <div
        class="my-16 py-10 bg-darkcard shadow-xs p-3"
        style="border-radius: 5px"
      >
        <div class="p-2">
          <div class="flex justify-center">
            <img class="h-24" src="../../assets/Login.svg" alt="" />
          </div>

          <div class="m-5 flex justify-center space-y-4">
            <p class="text-2xl text-blue-700">Forgot Password</p>
            <!-- <img src="../../p/Lock screen.svg" class="h-24 w-24 justify-start" alt="" /> -->
          </div>

          <form
            @submit.prevent="onSubmit"
            style="width: 350px"
            class="mt-6 space-y-2 max-w-2xl"
          >
            <input
              placeholder="Email"
              v-model.trim="formdata.email"
              @blur="$v.formdata.email.$touch()"
              class="bg-darkbg text-white outline-none w-full p-2 rounded"
            />
            <div v-if="$v.formdata.email.$error">
              <p
                class="text-xs text-red-600 font-semibold"
                v-if="!$v.formdata.email.required"
                id="error_label"
              >
                email is required!
              </p>
            </div>
            <div v-if="$v.formdata.email.$error">
              <p
                class="text-xs text-red-600 font-semibold"
                v-if="!$v.formdata.email.numeric"
                id="error_label"
              >
                Invalid email!
              </p>
            </div>
          </form>
          <div class="flex justify-end mx-5 space-x-6 mt-2">
            <div>
              <a
                @click="$router.push({ name: 'log-in' })"
                id="cursorHand"
                class="inline-block text-white align-baseline text-sm underline cursor-pointer"
              >
                go to login page!
              </a>
            </div>
          </div>
          <div class="flex justify-center">
            <button
              :disabled="forgetPasswordRequestSent"
              type="submit"
              Class="bg-text mt-5 flex space-x-3 justify-center py-1 w-full text-white"
              @click="onSubmit()"
            >
              <svg
                v-if="forgetPasswordRequestSent"
                class="h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Submit
            </button>
          </div>
          <p v-if="error" class="text-xs font-semibold text-red-700">
            please fill out the forms correctly!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
export default {
  components: {},
  data() {
    return {
      error: false,
      status: false,
      formdata: {
        email: "",
      },
      forgetPasswordRequestSent: false,
    };
  },

  validations: {
    formdata: {
      email: { required, email },
      // password: { required },
      // phoneNumber: { required, numeric },
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.forgetPasswordRequestSent = true;
        localStorage.setItem("email", this.formdata.email);
        this.$store
          .dispatch("Authentication/forgetPassword", this.formdata)
          .then((response) => {
            // console.log("response", response);
            if (response && !response.error) {
              localStorage.setItem("email", this.formdata.email);
              this.$router.push({
                name: "reset-password",
              });
            } else {
              this.forgetPasswordRequestSent = false;
            }
          });
      } else {
        // console.log("error");
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 5000);
      }
    },
    login() {
      this.$store
        .dispatch("Authentication/signIn", this.formdata)
        .then((response) => {
          if (response) {
            this.$router.push({
              name: "dashboard",
            });
          }
        });
    },
  },
};
</script>

<style scoped>
#bg {
  /* background-image: url("../../assets/Players/s64.jpg");
  background-image: url("../../assets/Players/s64.jpg"); */
  background-position: px;
  background-size: 380px;
  background-position-x: 450px;
  /* background-repeat: ; */
}
</style>
