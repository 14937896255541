<template>
  <div>
    <button
      type="button"
      v-on="$listeners"
      v-bind="$attrs"
      :class="buttonClass"
    >
      <i v-if="icon" class="pr-2" :class="icon"></i>
      <slot> </slot>
    </button>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    buttonClass: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
};
</script>

<style scoped>
.buttonClass {
  width: 450px;
}
.buttontwo {
  background-color: white;
  color: black;
  border: 2px solid #e7e7e7;
  width: auto;
}

.buttontwo:hover {
  background-color: #18d61e;
}
/* Group */
/* invite */
.buttoninvite {
  background-color: #d4eeff;
  color: #0099ff;
  border: 1px solid#0099FF;
  width: auto;
  border-radius: 10px;
}

/* .buttoninvite:hover {
  background-color: #00b1ff;
} */
/* mute */
.buttonmute {
  background-color: #ebecff;
  color: #8978f9;
  border: 1px solid #8978f9;
  width: auto;
  border-radius: 10px;
}

/* .buttonmute:hover {
  background-color: #8978f9;
} */
/* report */
.buttonreport {
  background-color: #ffd4a2;
  color: #ff8800;
  border: 1px solid #ff8800;
  width: auto;
  border-radius: 10px;
}

/* .buttonreport:hover {
  background-color: #ffd4a2;
} */
/* leave */
.buttonleave {
  background-color: #ffdcdc;
  color: #ff0000;
  border: 1px solid#FF0000;
  width: auto;
  border-radius: 10px;
}

/* .buttonleave:hover {
  background-color: #ffd4a2;
} */
/* end of group */
.smallnavigator {
  font-size: x-small;
  background-color: #0099ff;
  color: black;
  border: px solid #e7e7e7;
  width: auto;
  padding: 8px;
}
/* animation button */
.buttonanimate {
  display: inline-block;
  border-radius: 4px;
  background-color: #f4511e;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 15px;
  padding: 10px;
  width: 70px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.buttonanimate span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.buttonanimate span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.buttonanimate:hover span {
  padding-right: 15px;
}

.buttonanimate:hover span:after {
  opacity: 1;
  right: 0;
}
.textarea {
  position: absolute;
  top: 5px;
  left: 1px;
  right: 5px;
  border: none;
  width: px;
  height: px;
  z-index: 99;
  cursor: pointer;
  background-color: transparent;
  text-align: center;
}
.login {
  width: 200px;
  background-color: white;
  color: #0099ff;
  margin-top: 30px;
  transition-duration: 0.4s;
  transition-timing-function: linear;
}
.login:hover {
  background-image: linear-gradient(to left, #1d74d1, #00e5ff);
  color: white;
}
.add {
  border-radius: 50%;
  background-color: #ff0000;
}
.login2 {
  /* background-image: linear-gradient(to left, #1d74d1, #00e5ff); */
  background-color: #12008d;
  width: 275px;
  padding: 5px;
  border-radius: 0px;
  align-self: center;
  display: flex;
}
.android {
  /* background-color: #0099ff;
  padding: 5px;
  width: 130px;
  border-radius: 0px;

  text-align: center; */
  background-color: #0099ff;
  width: 137px;
  padding: 5px;
  border-radius: 0px;
}
.ios {
  /* background-color: #14173a;
  padding: 5px;
  width: 130px;
  border-radius: 0px; */
  background-color: #14173a;
  width: 137px;
  padding: 5px;
  border-radius: 0px;
}
.filters {
  padding: 5px;
  width: 70px;
  border-radius: 0px;
  background-color: #0099ff;
  color: white;
  font: xx-small;
}
.startchat {
  margin-top: 15px;
  padding: 5px;
  height: 50px;
  width: 575px;
  border-radius: 0px;
  background-color: rgb(69, 177, 216);
  color: white;
  font: xx-large;
}
.groupnext {
  text-align: center;
  margin-top: px;
  padding: px;
  height: 40px;
  width: 520px;
  border-radius: 25px;
  background-color: rgb(69, 177, 216);
  color: white;
  font: xx-large;
}
.groupcreate {
  text-align: center;
  margin-top: 20px;
  padding: px;
  height: 40px;
  width: 515px;
  border-radius: 25px;
  background-color: rgb(69, 177, 216);
  color: white;
  font: xx-large;
}
</style>
