<template>
  <div>
    <div class="flex justify-center py-4" v-if="noConnection">
      <network-issue />
    </div>
    <div
      v-if="!spinner && !noConnection"
      class="bg-darkcard mx-4 my-1 text-gray-300 rounded-md p-3"
    >
      <div class="grid grid-cols-7 text-base">
        <div v-if="branchEmployees.length !== 0" class="col-span-5 text-sm">
          {{ branchEmployees[0].agentId.agentName }} | Employees
        </div>
        <div v-if="branchEmployees.length === 0" class="col-span-5 text-sm">
          {{ activeAgentForDetails }} | Employees
        </div>
        <div>
          <!-- <button @click="modal" class="bg-text px-2 rounded-md text-xm">
          Add Employee
        </button> -->
        </div>
        <div>
          <div class="flex justify-center text-xs text-gray-300">
            <p
              class="cursor-pointer bg-text rounded-md p-1"
              @click="$router.go(-1)"
            >
              <i class="fa fa-back"></i>
              Go Back
            </p>
          </div>
          <!-- <BaseInput
          @keypress.enter="searchEmployee()"
          autocomplete="off"
          placeholder="search employee"
          type="Text"
          inputClass="px-2 bg-darkbg outline-none w-full p-1 rounded "
          v-model="search"
        /> -->
        </div>
      </div>
      <div
        v-if="branchEmployees.length !== 0"
        class="bg-darkbg my-2 rounded-xl overflow-x-auto"
      >
        <table class="table-auto w-full text-sm">
          <!-- {{
          employees
        }} -->
          <thead>
            <tr class="space-x-8 text-left font-light">
              <th class="border-b border-b-gray-700 px-1 font-light py-2">#</th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Employee Name
              </th>

              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Employee Info
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Employee Role
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Total Tickets
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Gross Income
              </th>

              <!-- <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Won Tickets
              </th> -->
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Total Payouts
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Profit
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Status
              </th>
              <!-- <th class="px-1 border-b border-b-gray-700 py-2 font-light">
              Detail
            </th> -->
              <!-- <th class="px-1 border-b border-b-gray-700 py-2 font-light">
              Action
            </th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(emp, index) in branchEmployees"
              :key="index"
              class="text-gray-400"
            >
              <td class="px-1 py-2">{{ index + 1 }}</td>
              <td class="px-1 py-2">{{ emp.employeeName }}</td>
              <td class="px-1 py-2">{{ emp.phoneNumber }}</td>
              <td class="px-1 py-2">{{ emp.role }}</td>
              <td class="px-1 py-2">{{ emp.totalTickets.toLocaleString() }}</td>
              <td class="px-1 py-2">
                {{ emp.unreportedTotalPlacedMoney.toLocaleString() }}
              </td>

              <td class="px-1 py-2 text-red-500">
                <!-- ({{ emp.totalWonticketsCount.toLocaleString() }}) -->
                {{ emp.unreportedTotalPayoutMoney.toLocaleString() }} birr
              </td>
              <!-- <td class="px-1 py-2 text-yellow-500">
                ({{ emp.totalLosticketsCount.toLocaleString() }})
                {{ emp.totalLosticketsPlacedMoney.toLocaleString() }} birr
              </td> -->
              <td class="px-1 text-green-500 flex space-x-2 py-2">
                {{
                  (
                    emp.unreportedTotalPlacedMoney -
                    emp.unreportedTotalPayoutMoney
                  ).toLocaleString()
                }}
                <!-- <p>{{ emp.totalProfit.toLocaleString() }} birr</p> -->
              </td>
              <td class="px-1 object-contain space-x-5 text-left py-2">
                <label class="relative inline-flex items-center cursor-pointer">
                  <input
                    v-model="status[emp.id]"
                    @click="toggle(emp.id, emp.active)"
                    type="checkbox"
                    value=""
                    class="sr-only peer"
                  />
                  <div
                    class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                  ></div>
                </label>
                <!-- <input
                  class="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  v-model="status[emp.id]"
                  @click="toggle(emp.id, emp.active)"
                /> -->
              </td>
              <!-- <td class="px-1 object-contain space-x-5 text-right py-2">
              <p class="w-4 h-4">More</p>
            </td> -->
              <!-- <td class="px-2 object-contain space-x-5 text-right flex py-2">
              <img
                @click="editEmployeeInfo(emp.id)"
                class="w-4 h-4"
                src="../../assets/icons/edit.svg"
                alt=""
                srcset=""
              />
              <img
                class="w-4 h-4"
                src="../../assets/icons/delete.svg"
                alt=""
                srcset=""
              />
            </td> -->
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="branchEmployees.length === 0"
        class="text-3xl bg-darkbg p-5 mt-5 text-white text-center"
      >
        There are no employees registered!!
      </div>
      <div v-if="branchEmployees.length !== 0" class="text-xs flex justify-end">
        <div class="flex space-x-5">
          <div class="flex space-x-8">
            <template v-if="page !== 1">
              <router-link :to="{ query: { page: page - 1 } }" rel="prev">
                ← Prev Page
              </router-link>
            </template>
            <div
              class="ml-2 text-sm cursor-pointer"
              :class="{
                'bg-text m w-8 rounded-md text-white text-center':
                  page === $route.query.page,
              }"
              @click="paginate(page)"
              v-for="(page, i) in letsTry"
              :key="i"
            >
              {{ page }}
            </div>
            <template v-if="page < letsTry.length">
              <router-link :to="{ query: { page: page + 1 } }" rel="prev"
                >Next Page →</router-link
              >
            </template>
          </div>
          <!-- <div>1 of 56 pages</div> -->
        </div>
      </div>
    </div>
    <div class="flex justify-center py-16" v-if="spinner"><spinner2 /></div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import spinner2 from "@/components/spinner2.vue";
import NetworkIssue from "@/Error/NetworkIssue.vue";
import AddEmployee from "../../components/Modal/Employees/AddEmployee.vue";
import EditEmployee from "../../components/Modal/Employees/EditEmployee.vue";
export default {
  components: {
    AddEmployee,
    EditEmployee,
    spinner2,
    NetworkIssue,
  },
  data() {
    return {
      noConnection: false,
      spinner: false,
      search: "",
      agentName: "",
      status: {},
    };
  },
  created() {
    this.spinner = true;
    this.$store
      .dispatch("branches/getBranchEmployees", this.$route.params.id)
      .then((response) => {
        if (response) {
          this.spinner = false;
          this.arrangeToggle();
        } else {
          this.spinner = false;
          this.noConnection = true;
        }
      });
  },
  mounted() {
    this.agentName = this.$route.params.agentName;
  },
  computed: {
    ...mapGetters("modals", ["addemployee"]),
    ...mapGetters("modals", ["editemployee"]),
    ...mapState("branches", ["branchEmployees"]),
    ...mapState("branches", ["activeAgentForDetails"]),
    letsTry() {
      let page = [];
      let pages = this.allTickets / 13;
      for (var i = 1; i < pages + 1; i++) {
        page.push(i);
      }
      return page;
    },
    page() {
      return parseInt(this.$route.query.page) || 1;
    },
  },
  methods: {
    editEmployeeInfo(id) {
      this.$store
        .dispatch("branchEmployees/getEmployeeById", id)
        .then((response) => {
          if (response) {
            this.editEmployee();
          }
        });
    },
    arrangeToggle() {
      this.status = {};
      // console.log("hehe");
      if (this.branchEmployees) {
        // console.log("overhh: ", this.branchEmployees);
        this.branchEmployees.forEach((b) => {
          this.status[b.id] = b.active;
          // console.log("stat.active", b.active);
        });
      }
    },
    toggle(id) {
      // console.log("id: ", id);
      this.$store.dispatch("branches/updateEmployeeByProxima", id);
    },
    modal() {
      this.$store.dispatch("modals/modal", {
        stat: "addEmployee",
        status: !this.addemployee,
      });
    },
    editEmployee() {
      this.$store.dispatch("modals/modal", {
        stat: "editEmployee",
        status: !this.editemployee,
      });
    },
    paginate(selectedPage) {
      this.$router.push({ query: { page: selectedPage } });
      this.$store.dispatch("cashier/getAllTickets", {
        perPage: 13,
        page: this.page,
      });
    },
    searchEmployee() {
      this.$store.dispatch("branchEmployees/searchEmployee", this.search);
    },
  },
};
</script>

<style></style>
