<template>
  <div>
    <section class="bg-darkbg">
      <div class="px-4 p-4 grid grid-cols-3 gap-3">
        <div v-for="(comment, i) in messages" :key="i">
          <article class="text-base bg-darkcard rounded-lg p-2">
            <div class="bg-darkbg p-2">
              <footer class="flex justify-between mb-2">
                <div class="flex justify-between items-center">
                  <!-- <p
                    class="inline-flex items-center mr-3 text-sm text-white font-semibold"
                  >
                    {{ comment._id }}
                  </p> -->
                  <p class="text-sm text-white">
                    {{ comment.createdAt | date }}
                  </p>
                </div>
              </footer>
              <p class="text-white">
                {{ comment.comment }}
              </p>
            </div>
          </article>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      messages: [],
    };
  },
  created() {
    this.$store.dispatch("branches/getMessages").then((res) => {
      if (res) {
        this.messages = res.doc;
      }
    });
  },
};
</script>

<style></style>
