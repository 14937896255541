<template>
  <div>
    <div class="flex justify-center py-4" v-if="noConnection">
      <network-issue />
    </div>
    <div
      v-if="!noConnection"
      class="bg-darkcard mx-4 my-1 text-gray-300 rounded-md p-3"
    >
      <BaseModal modalClass="w-3/5" :visible="addBranchStatus">
        <AddBranch
      /></BaseModal>
      <BaseModal :visible="editBranchStatus">
        <EditBranch :branch="branch"
      /></BaseModal>
      <div class="grid grid-cols-7 text-base">
        <div class="col-span-5">Branches</div>
        <div>
          <button @click="modal" class="bg-text px-2 p-1 rounded-md text-xm">
            Add Branch
          </button>
        </div>
      </div>
      <div class="bg-darkbg my-2 rounded-xl overflow-x-auto">
        <table class="table-auto w-full text-sm">
          <thead>
            <tr class="space-x-8 text-left font-light">
              <th class="border-b border-b-gray-700 px-1 font-light py-2">#</th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Branch Address
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Branch Admin
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Admin Info
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Total Tickets
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Gross Income
              </th>

              <!-- <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Neutral Tickets
              </th> -->
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Total Payout
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Profit
              </th>
              <!-- <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Profit
              </th> -->
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Safety
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Auto-Safety
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Status
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Detail
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="Branches.length !== 0"
              v-for="(b, index) in Branches"
              :key="index"
              class="text-gray-400"
            >
              <td class="px-1 py-2">{{ index + 1 }}</td>
              <td class="px-1 py-2">{{ b.branchLocation }}</td>
              <td class="px-1 py-2">{{ b.agentName }}</td>
              <td class="px-1 py-2">{{ b.phoneNumber }}</td>
              <!-- <td class="px-1 py-2">{{ b.active }}</td> -->
              <td class="px-1 py-2">{{ b.unreportedTotalTickets }}</td>
              <td class="px-1 py-2 text-1">
                <!-- {{ b.totalPlacedMoney.toLocaleString() }} -->
                {{ b.unreportedTotalPlacedMoney.toLocaleString() }}
              </td>

              <!-- <td class="px-1 py-2">{{ b.totalTickets }}</td> -->
              <!-- <td class="px-1 py-2">
                ----
            
              </td> -->
              <td class="cursor-pointer px-1 py-2 text-green-500">
                <!-- ({{ b.totalLosticketsCount.toLocaleString() }}) -->
                <!-- {{ b.totalLosticketsPlacedMoney.toLocaleString() }} birr -->
                {{ b.unreportedTotalPayoutMoney.toLocaleString() }} birr
              </td>
              <td
                class="cursor-pointer px-1 text-yellow-500 flex space-x-2 py-2"
              >
                <!-- <p>{{ b.totalProfit.toLocaleString() }} birr</p> -->
                <p>
                  {{
                    (
                      b.unreportedTotalPlacedMoney -
                      b.unreportedTotalPayoutMoney
                    ).toLocaleString()
                  }}
                  birr
                </p>
              </td>

              <td
                class="cursor-pointer px-1 object-contain space-x-5 text-right py-2"
              >
                <!-- <input
                  class="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  v-model="Safety[b.id]"
                  @click="toggleSafety(b.id, b.active)"
                /> -->
                <label class="relative inline-flex items-center cursor-pointer">
                  <input
                    v-model="Safety[b.id]"
                    @click="toggleSafety(b.id, b.active)"
                    type="checkbox"
                    value=""
                    class="sr-only peer"
                  />
                  <div
                    class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                  ></div>
                </label>
              </td>
              <td
                class="cursor-pointer px-1 object-contain space-x-5 text-right py-2"
              >
                <!-- <input
                  class="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  v-model="AutoSafety[b.id]"
                  @click="toggleAutoSafety(b.id, b.active)"
                /> -->
                <label
                  class="relative inline-flex justify-start items-start cursor-pointer"
                >
                  <input
                    v-model="AutoSafety[b.id]"
                    @click="toggleAutoSafety(b.id, b.active)"
                    type="checkbox"
                    value=""
                    class="sr-only peer"
                  />
                  <div
                    class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                  ></div>
                </label>
              </td>
              <td
                class="cursor-pointer px-1 object-contain space-x-5 text-right py-2"
              >
                <!-- <input
                  class="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  v-model="status[b.id]"
                  @click="toggle(b.id, b.active)"
                /> -->
                <label class="relative inline-flex items-start cursor-pointer">
                  <input
                    v-model="status[b.id]"
                    @click="toggle(b.id, b.active)"
                    type="checkbox"
                    value=""
                    class="sr-only peer"
                  />
                  <div
                    class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                  ></div>
                </label>
              </td>
              <td
                @click="branchDetailsStatus(b)"
                class="cursor-pointer px-1 py-2"
              >
                More
              </td>
              <td
                class="px-2 cursor-pointer object-contain space-x-5 text-right flex py-2"
              >
                <img
                  @click="editBranchModal(b)"
                  class="w-4 h-4"
                  src="../../assets/icons/edit.svg"
                  alt=""
                  srcset=""
                />
                <!-- <img
                class="w-4 h-4"
                src="../../assets/icons/delete.svg"
                alt=""
                srcset=""
              /> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="text-3xl py-24 w-full text-center"
        v-if="Branches.length === 0 && !Branches == null"
      >
        There are no branches available!
      </div>
      <div v-if="totalBranches > 13" class="text-xs flex justify-end">
        <div class="flex space-x-5">
          <div class="flex space-x-2">
            <template v-if="page !== 1">
              <div
                class="cursor-pointer"
                @click="paginate(page - 1)"
                rel="prev"
              >
                ← Prev Page
              </div>
            </template>
            <div
              class="ml-2 w-8 text-sm cursor-pointer"
              @click="paginate(currentPage)"
              v-for="(currentPage, i) in letsTry"
              :key="i"
              :class="{ 'text-center rounded-md bg-text': page == currentPage }"
            >
              {{ currentPage }}
            </div>
            <template v-if="page < letsTry.length">
              <div
                @click="paginate(page + 1)"
                rel="prev"
                class="cursor-pointer"
              >
                Next Page →
              </div>
            </template>
          </div>
          <!-- <div>1 of 56 pages</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import NetworkIssue from "@/Error/NetworkIssue.vue";
import AddBranch from "../../components/Modal/Branches/AddBranch.vue";
import EditBranch from "../../components/Modal/Branches/EditBranch.vue";
export default {
  data() {
    return {
      noConnection: false,
      branch: "",
      search: "",
      status: {},
      Safety: [],
      AutoSafety: [],
      totalBranches: 0,
    };
  },
  components: {
    AddBranch,
    EditBranch,
    NetworkIssue,
  },
  created() {
    this.$store.dispatch("branches/getAllBranches").then((response) => {
      if (response) {
        this.arrangeToggle();
        this.arrangeToggleForSafety();
        this.arrangeToggleForAutoSafety();
      } else {
        this.noConnection = true;
      }
    });
    this.$store.dispatch("branches/countDocument").then((response) => {
      if (response) {
        this.totalBranches = response;
      }
    });
  },
  mounted() {},
  computed: {
    letsTry() {
      let page = [];
      let pages = this.totalBranches / 13;
      for (var i = 1; i < pages + 1; i++) {
        page.push(i);
      }
      return page;
    },
    page() {
      return parseInt(this.$route.query.page) || 1;
    },
    ...mapGetters("modals", ["addBranchStatus"]),
    ...mapGetters("modals", ["editBranchStatus"]),
    // ...mapGetters("modals", ["branchDetailsStatus"]),
    ...mapState("branches", ["Branches"]),
  },
  methods: {
    modal() {
      this.$store.dispatch("modals/modal", {
        stat: "addBranch",
        status: !this.addBranchStatus,
      });
    },
    paginate(selectedPage) {
      this.$router.push({ query: { page: selectedPage } });
      this.$store.dispatch("branches/getAllBranches", {
        perPage: 13,
        page: this.page,
      });
    },
    editBranchModal(branch) {
      this.branch = branch;
      this.$store.dispatch("modals/modal", {
        stat: "editBranch",
        status: !this.editBranchStatus,
      });
    },
    arrangeToggle() {
      this.status = {};
      // console.log("hehe");
      if (this.Branches) {
        // console.log("overhh: ", this.Branches);
        this.Branches.forEach((b) => {
          this.status[b.id] = b.active;
          // console.log("stat.active", b.active);
        });
      }
    },
    arrangeToggleForSafety() {
      // this.status = {};
      this.Safety = {};
      // console.log("hehe");
      if (this.Branches) {
        // console.log("overhh: ", this.Branches);
        this.Branches.forEach((b) => {
          this.Safety[b.id] = b.safeMode;
        });
      }
    },
    arrangeToggleForAutoSafety() {
      this.AutoSafe = {};
      if (this.Branches) {
        this.Branches.forEach((b) => {
          this.AutoSafety[b.id] = b.autoSafeMode;
          // console.log("stat.active", b.active);
        });
      }
    },
    // branchDetailsStatusModal() {
    //   // this.branch = branch;
    //   this.$store.dispatch("modals/modal", {
    //     stat: "branchDetailsStatus",
    //     status: !this.branchDetailsStatus,
    //   });
    // },
    searchBranch() {
      this.$store.dispatch("branches/searchBranch", this.search);
    },
    branchDetailsStatus(b) {
      this.$router.push({
        name: "branch-details",
        params: { id: b.id },
      });
      this.$store.dispatch("branches/activeName", b.agentName);
    },
    toggle(id, status) {
      // console.log("id: ", id);
      // this.mode = !status;
      this.$store.dispatch("branches/activateBranchAccount", id);
    },
    toggleSafety(id, status) {
      // console.log("id: ", id);
      // this.mode = !status;
      this.$store.dispatch("branches/toggleSafety", id);
    },
    toggleAutoSafety(id, status) {
      // console.log("id: ", id);
      // this.mode = !status;
      this.$store.dispatch("branches/toggleAutoSafety", id);
    },
  },
};
</script>

<style>
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: px;
  bottom: -0px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: rgb(46, 43, 43);
}

input:focus + .slider {
  box-shadow: 0 0 1px black;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
