<template>
  <div class="p-8">
    <div class="flex justify-between mx-auto mb-16">
      <p class="text-gray-400">Add Branch</p>
      <img
        @click="close()"
        class="cursor-pointer"
        src="../../../assets/icons/x.svg"
        alt=""
        srcset=""
      />
    </div>
    <form @submit.prevent="onSubmit" class="w-full grid grid-cols-2 gap-5">
      <div>
        <BaseInput
          label="name"
          type="text"
          inputClass="bg-darkbg outline-none w-full p-2 rounded "
          v-model.trim="formdata.agentName"
          @blur="$v.formdata.agentName.$touch()"
        />
        <div v-if="$v.formdata.agentName.$error">
          <p
            class="text-xs text-red-600"
            v-if="!$v.formdata.agentName.required"
            id="error_label"
          >
            Branch name is required!
          </p>
        </div>
      </div>
      <div>
        <BaseInput
          label="Email"
          type="Email"
          inputClass="bg-darkbg outline-none w-full p-2 rounded "
          v-model.trim="formdata.email"
          @blur="$v.formdata.email.$touch()"
        />
        <div v-if="$v.formdata.email.$error">
          <p
            class="text-xs text-red-500"
            v-if="!$v.formdata.email.required"
            id="error_label"
          >
            email is required!
          </p>
        </div>
        <div v-if="$v.formdata.email.$error && $v.formdata.email.required">
          <p
            class="text-xs text-red-500"
            v-if="!$v.formdata.email.email"
            id="error_label"
          >
            invalid email!
          </p>
        </div>
      </div>
      <div>
        <BaseInput
          autocomplete="off"
          label="Phone Number"
          inputClass="bg-darkbg outline-none w-full p-2 rounded "
          v-model.trim="formdata.phoneNumber"
          type="number"
          @blur="$v.formdata.phoneNumber.$touch()"
        />
        <div v-if="$v.formdata.phoneNumber.$error">
          <p
            class="text-xs text-red-500"
            v-if="!$v.formdata.phoneNumber.required"
            id="error_label"
          >
            Phone number is required!
          </p>
        </div>
      </div>
      <div>
        <BaseInput
          autocomplete="off"
          label="branchLocation"
          inputClass="bg-darkbg outline-none w-full p-2 rounded "
          v-model.trim="formdata.branchLocation"
          type="text"
          @blur="$v.formdata.branchLocation.$touch()"
        />
        <div v-if="$v.formdata.branchLocation.$error">
          <p
            class="text-xs text-red-500"
            v-if="!$v.formdata.branchLocation.required"
            id="error_label"
          >
            branchLocation is required!
          </p>
        </div>
      </div>

      <div>
        <BaseInput
          autocomplete="off"
          label="Password"
          type="Password"
          inputClass="bg-darkbg outline-none w-full p-2 rounded "
          v-model.trim="formdata.password"
          :passwordInput="true"
          @blur="$v.formdata.password.$touch()"
        />

        <div v-if="$v.formdata.password.$error">
          <p
            class="text-xs text-red-500"
            v-if="!$v.formdata.password.required"
            id="error_label"
          >
            password is required!
          </p>
        </div>
        <div v-if="$v.formdata.password.$error">
          <p
            class="text-xs text-red-600"
            v-if="!$v.formdata.password.minLength"
            id="error_label"
          >
            Password must be greater than 6 characters!
          </p>
          <p
            class="text-xs text-red-600"
            v-if="!$v.formdata.password.maxLength"
            id="error_label"
          >
            Password must be less than 12 characters!
          </p>
        </div>
      </div>
      <div>
        <BaseInput
          autocomplete="off"
          class=""
          type="password"
          :passwordInput="true"
          inputClass="bg-darkbg outline-none w-full p-2 rounded "
          label="Confirm Password"
          v-model.trim="formdata.passwordConfirm"
          @blur="$v.formdata.passwordConfirm.$touch()"
        />
        <div v-if="$v.formdata.passwordConfirm.$error">
          <p
            class="text-xs text-red-500"
            v-if="!$v.formdata.passwordConfirm.sameAs"
            id="error_label"
          >
            Passwords don't match!
          </p>
        </div>
        <!-- < text-red-500div v-if="$v.formdata.passwordConfirm.$error">
            <p class="text-xs" v-if="!$v.formdata.passwordConfirm.required" id="error_label">
              confirm password is required!
            </p>
          </div> -->
      </div>
    </form>
    <div class="w-full">
      <BaseButton
        @click="onSubmit"
        class="w-full cursor-pointer flex text-base justify-center mt-5 p-2 bg-text"
        >Register</BaseButton
      >
      <p v-if="error" class="text-xs text-red-600">
        please fill out all the form correctly!
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  required,
  email,
  maxLength,
  minLength,
  sameAs,
} from "vuelidate/lib/validators";
export default {
  data() {
    return {
      error: false,
      status: false,
      formdata: {
        agentName: "",
        email: "",
        phoneNumber: "",
        branchLocation: "",
        password: "",
        passwordConfirm: "",
      },
    };
  },
  validations: {
    formdata: {
      email: { required, email },
      agentName: { required },
      phoneNumber: { required },
      passwordConfirm: { required, passwordConfirm: sameAs("password") },
      password: { required, minLength: minLength(8), maxLength: maxLength(12) },
      branchLocation: { required },
    },
  },
  computed: {
    ...mapGetters("modals", ["addBranchStatus"]),
  },
  methods: {
    close() {
      this.$store.dispatch("modals/closeAll");
    },

    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("branches/createBranch", this.formdata)
          .then((response) => {
            // console.log("response", response);
            if (response && !response.error) {
              this.$store.dispatch("modals/modal", {
                stat: "addBranch",
                status: !this.addBranchStatus,
              });
            }
            // console.log("resrsr: ", this.formdata);
          });
      } else {
        // console.log("error");
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 5000);
      }
    },
  },
};
</script>

<style></style>
