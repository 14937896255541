<template>
  <div>
    <transition name="fade" appear="">
      <div class="modal-overlay" v-if="visible" @click="close()"></div>
    </transition>

    <transition name="modal" appear="">
      <div :class="modalClass" class="moodal" style="" v-if="visible">
        <div class="rounded-lg overflow-y-auto w-full h-full" style="">
          <div class="h-full w-full">
            <slot></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    modalClass: {
      default: "w-3/5 top-8",
      type: String,
    },
    callBack: {
      type: String,
    },
    isClosable: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    close() {
      if (this.isClosable) {
        // console.log("d: ", this.callBack);
        this.$store.dispatch("modals/closeAll");
        if (this.callBack) {
          const name = this.callBack;
          this.$parent[name](this.$parent.id);
        }
      }
    },
  },
};
</script>

<style scoped>
/* Add your custom styles for the modal and overlay here */
.moodal {
  position: fixed;
  z-index: 99;
  background-color: #2c2c2e;
  top: 6%;
  left: 50%;
  transform: translate(-50%, 6%);
}

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 98;
  background-color: rgba(0, 0, 0, 0.6);
}
.modal-enter-active,
.modal-leave-active {
  transition: all 0.5s ease;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
  transform: translate(-50%, 6%) scale(0.5);
}
</style>
