<template>
  <div class="text-white grid grid-cols-2">
    <div class="p-8 w-full">
      <form
        @submit.prevent="updateProfileFun"
        class="bg-darkcard p-3 w-full grid grid-row-3 gap-5"
      >
        <div>Change Profile</div>
        <div>
          <BaseInput
            label="name"
            inputClass="bg-darkbg outline-none w-full p-2 rounded "
            v-model.trim="updateProfile.employeeName"
            @blur="$v.updateProfile.employeeName.$touch()"
          />
          <div v-if="$v.updateProfile.employeeName.$error">
            <p
              class="text-xs text-red-600"
              v-if="!$v.updateProfile.employeeName.required"
              id="error_label"
            >
              Employee name is required!
            </p>
          </div>
        </div>
        <div>
          <BaseInput
            label="Email"
            type="Email"
            inputClass="bg-darkbg outline-none w-full p-2 rounded "
            v-model.trim="updateProfile.email"
            @blur="$v.updateProfile.email.$touch()"
          />
          <div v-if="$v.updateProfile.email.$error">
            <p
              class="text-xs text-red-500"
              v-if="!$v.updateProfile.email.required"
              id="error_label"
            >
              email is required!
            </p>
          </div>
          <div
            v-if="
              $v.updateProfile.email.$error && $v.updateProfile.email.required
            "
          >
            <p
              class="text-xs text-red-500"
              v-if="!$v.updateProfile.email.email"
              id="error_label"
            >
              invalid email!
            </p>
          </div>
        </div>
        <div>
          <BaseInput
            autocomplete="off"
            label="Phone Number"
            inputClass="bg-darkbg outline-none w-full p-2 rounded "
            v-model.trim="updateProfile.phoneNumber"
            type="number"
            @blur="$v.updateProfile.phoneNumber.$touch()"
          />
          <div v-if="$v.updateProfile.phoneNumber.$error">
            <p
              class="text-xs text-red-500"
              v-if="!$v.updateProfile.phoneNumber.required"
              id="error_label"
            >
              Phone number is required!
            </p>
          </div>
        </div>
        <div class="w-full">
          <BaseButton
            buttonClass="w-full"
            @click="updateProfileFun()"
            class="w-full cursor-pointer flex text-base justify-center mt-5 p-2 bg-text"
            >Update Profile</BaseButton
          >
          <p v-if="error" class="text-xs text-red-600">
            please fill out all the form correctly!
          </p>
        </div>
      </form>
    </div>
    <div class="p-8 w-full">
      <form
        @submit.prevent="updatePasswordFun"
        class="bg-darkcard p-3 w-full grid grid-row-3 gap-5"
      >
        <div>Change Password</div>

        <div>
          <BaseInput
            autocomplete="off"
            label="current password"
            type="Password"
            inputClass="bg-darkbg outline-none w-full p-2 rounded "
            v-model="updatePassword.password"
          />
        </div>
        <div>
          <BaseInput
            autocomplete="off"
            label="Password"
            type="Password"
            inputClass="bg-darkbg outline-none w-full p-2 rounded "
            v-model="updatePassword.password"
          />
        </div>
        <div>
          <BaseInput
            autocomplete="off"
            class=""
            type="password"
            inputClass="bg-darkbg outline-none w-full p-2 rounded "
            label="Confirm Password"
            v-model="updatePassword.passwordConfirm"
          />
        </div>
        <div class="w-full">
          <BaseButton
            buttonClass="w-full"
            @click="updatePasswordFun()"
            class="w-full cursor-pointer flex text-base justify-center mt-5 p-2 bg-text"
            >Update Password</BaseButton
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  // maxLength,
  // minLength,
  // sameAs,
} from "vuelidate/lib/validators";
export default {
  data() {
    return {
      error: false,
      // error2: false,
      status: false,
      updateProfile: {
        email: "",
        employeeName: "",
        phoneNumber: "",
      },
      updatePassword: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
    };
  },
  validations: {
    updateProfile: {
      email: { required, email },
      employeeName: { required },
      phoneNumber: { required },
    },
    // updatePassword: {
    //   oldPassword: { required },
    //   passwordConfirm: { required, passwordConfirm: sameAs("password") },
    //   password: { required, minLength: minLength(8), maxLength: maxLength(12) },
    // },
  },
  created() {
    let user = localStorage.getItem("user");
    this.user = JSON.parse(user);
    this.populate();
  },
  methods: {
    populate() {
      this.updateProfile.employeeName = this.user.employeeName;
      this.updateProfile.email = this.user.email;
      this.updateProfile.phoneNumber = this.user.phoneNumber;
    },
    updateProfileFun() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.dispatch(
          "Authentication/updateMyProfile",
          this.updateProfile
        );
      } else {
        // console.log("error");
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 5000);
      }
    },
    updatePasswordFun() {
      // this.$v.$touch();
      // if (!this.$v.$invalid) {
      this.$store
        .dispatch("Authentication/updateMyPassword", this.formdata)
        .then((response) => {
          // console.log("response", response);
          // console.log("resrsr: ", this.formdata);
        });
      // } else {
      //   console.log("error");
      //   this.error2 = true;
      //   setTimeout(() => {
      //     this.error2 = false;
      //   }, 5000);
      // }
    },
  },
};
</script>

<style></style>
