<template>
  <div>
    <div class="flex justify-center py-4" v-if="noConnection">
      <network-issue />
    </div>
    <div v-if="!noConnection" class="bg-darkcard p-2 text-gray-300">
      <BaseModal :visible="addEmployeeStatus"> <AddEmployee /></BaseModal>
      <BaseModal :visible="editEmployeeStatus">
        <EditEmployee :info="infoToBeEdited"
      /></BaseModal>
      <div class="grid grid-cols-7 text-base">
        <div class="col-span-5 text-base">Employees</div>
        <div>
          <BaseButton
            @click="modal"
            icon="fa fa-plus"
            buttonClass="bg-text px-4 py-1 text-base"
            >Add</BaseButton
          >
        </div>
      </div>
      <div class="bg-darkbg w-full my-2 overflow-x-auto">
        <table class="table-auto w-full text-sm overflow-x-auto">
          <thead>
            <tr class="space-x-8 text-left font-light">
              <th class="border-b border-b-gray-700 px-1 font-light py-2">#</th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Employee Name
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Employee Info
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Employee Role
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Total Tickets
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Total Income
              </th>

              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Total Payout
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Profit
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Report
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Status
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Weekly Report
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Action
              </th>
            </tr>
          </thead>
          <tbody v-if="!progressStatus.employee">
            <tr
              v-for="(emp, index) in employees"
              :key="index"
              class="text-gray-400"
            >
              <td class="px-1 py-2">{{ index + 1 }}</td>
              <td class="px-1 py-2">{{ emp.employeeName }}</td>
              <td class="px-1 py-2">{{ emp.phoneNumber }}</td>
              <td class="px-1 py-2">{{ emp.role }}</td>
              <td class="px-1 py-2">
                {{ emp.unreportedTotalTickets.toLocaleString() }}
              </td>
              <td class="px-1 py-2 text-1">
                {{ emp.unreportedTotalPlacedMoney.toLocaleString() }} birr
              </td>

              <td class="px-1 py-2 text-red-500">
                <!-- ({{ emp.totalWonticketsCount }}) -->
                {{ emp.unreportedTotalPayoutMoney.toLocaleString() }} birr
              </td>

              <td
                class="px-1 text-green-500 flex space-x-2 py-2 mt-1"
                :class="{
                  ' text-green-500':
                    Number(emp.unreportedTotalPlacedMoney) -
                      Number(emp.unreportedTotalPayoutMoney) >
                    0,
                  ' text-redo':
                    Number(emp.unreportedTotalPlacedMoney) -
                      Number(emp.unreportedTotalPayoutMoney) <
                    0,
                }"
              >
                <!-- <p>{{ emp.totalProfit }} birr</p> -->
                {{
                  (
                    emp.unreportedTotalPlacedMoney -
                    emp.unreportedTotalPayoutMoney
                  ).toLocaleString()
                }}
                birr
              </td>

              <td class="px-1 object-contain space-x-5 text-center py-2">
                <div class="flex space-x-3">
                  <div
                    v-if="
                      Number(emp.unreportedTotalPlacedMoney) -
                        Number(emp.unreportedTotalPayoutMoney) !==
                      0
                    "
                  >
                    {{
                      Number(emp.unreportedTotalPlacedMoney) -
                      Number(emp.unreportedTotalPayoutMoney)
                    }}
                    <!-- <BaseButton
                      @click="closeTodaysReport(emp)"
                      class="bg-blue-500 text-xs text-white p-1"
                      >Report</BaseButton
                    > -->
                  </div>
                  <div
                    v-if="
                      Number(emp.unreportedTotalPlacedMoney) -
                        Number(emp.unreportedTotalPayoutMoney) ===
                      0
                    "
                    class="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]"
                  >
                    0
                  </div>
                </div>
              </td>
              <td class="px-1 object-contain space-x-5 text-left py-2">
                <label class="relative inline-flex items-center cursor-pointer">
                  <input
                    @click="toggle(emp.id, emp.active)"
                    v-model="status[emp.id]"
                    type="checkbox"
                    value=""
                    class="sr-only peer"
                  />
                  <div
                    class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                  ></div>
                </label>
              </td>

              <td class="px-2 object-contain space-x-5 text-right flex py-2">
                <p
                  class="cursor-pointer"
                  @click="getEmployeeTransaction(emp.id)"
                >
                  More
                </p>
              </td>

              <td>
                <BaseButton @click="editEmployeeInfo(emp)"
                  ><img
                    class="w-4 h-4"
                    src="../../assets/icons/edit.svg"
                    alt=""
                    srcset=""
                /></BaseButton>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <div class="w-full flex justify-center" v-if="progressStatus.employee">
          <div class="flex justify-center py-16">
            <spinner2 class="flex justify-center" />
          </div>
        </div> -->
        <div
          class="text-3xl py-24 w-full text-center"
          v-if="employees.length == 0 && !employees == null"
        >
          There are no employees available!
        </div>
      </div>
      <div class="text-xs flex justify-end">
        <div class="flex space-x-5">
          <div class="flex space-x-8">
            <template v-if="page !== 1">
              <router-link :to="{ query: { page: page - 1 } }" rel="prev">
                ← Prev Page
              </router-link>
            </template>
            <div
              class="ml-2 text-sm cursor-pointer"
              :class="{
                'bg-text m w-8 rounded-md text-white text-center':
                  page === $route.query.page,
              }"
              @click="paginate(page)"
              v-for="(page, i) in letsTry"
              :key="i"
            >
              {{ page }}
            </div>
            <template v-if="page < letsTry.length">
              <router-link :to="{ query: { page: page + 1 } }" rel="prev"
                >Next Page →</router-link
              >
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import NetworkIssue from "@/Error/NetworkIssue.vue";
// import ReportModal from "@/components/Modal/ReportModal.vue";

// import spinner from "@/components/spinner.vue";
import AddEmployee from "../../components/Modal/Employees/AddEmployee.vue";
import EditEmployee from "../../components/Modal/Employees/EditEmployee.vue";
// import spinner2 from "../spinner2.vue";
export default {
  components: {
    AddEmployee,
    EditEmployee,
    // spinner,
    NetworkIssue,
    // spinner2,
    // ReportModal,
  },
  data() {
    return {
      employees: [],
      reportData: [],
      noConnection: false,
      // spinner: false,
      search: "",
      edit: false,
      status: {},
      zero: true,
      infoToBeEdited: [],
    };
  },
  props: {
    id: {
      type: String,
    },
  },

  created() {
    // this.spinner = true;
    this.$store.state.branches.progressStatus.employee = true;
    this.fetchBranchEmployees(this.id);
  },
  watch: {
    id(newValue) {
      this.fetchBranchEmployees(newValue);
    },
  },
  computed: {
    ...mapGetters("modals", ["addEmployeeStatus"]),
    ...mapGetters("modals", ["editEmployeeStatus"]),
    ...mapState("branches", ["progressStatus"]),
    ...mapGetters("modals", ["reportModalStatus"]),
    letsTry() {
      let page = [];
      let pages = this.allTickets / 13;
      for (var i = 1; i < pages + 1; i++) {
        page.push(i);
      }
      return page;
    },
    page() {
      return parseInt(this.$route.query.page) || 1;
    },
    letsRun() {
      if (this.employees) {
        return this.arrangeToggle();
      }
    },
  },
  methods: {
    async fetchBranchEmployees() {
      try {
        const id = await localStorage.getItem("activeAgent");
        this.$store.state.branches.progressStatus.employee = true;
        if (this.$route.query.page) {
          this.$router.push({ query: {} });
        }
        this.$store
          .dispatch("branches/getBranchEmployees", id)
          .then((response) => {
            if (response) {
              this.employees = response;
              this.$store;
              // this.spinner = false;
              this.$store.state.branches.progressStatus.employee = false;
              this.arrangeToggle();
            } else {
              // this.spinner = false;
              this.noConnection = true;
            }
          });
      } catch (error) {}
    },
    arrangeToggle() {
      this.status = {};
      // console.log("hehe");
      if (this.employees) {
        // console.log("overhh: ", this.employees);
        this.employees.forEach((b) => {
          this.status[b.id] = b.active;
          // console.log("stat.active", b.active);
        });
      }
    },
    getEmployeeTransaction(id) {
      this.$router.push({ name: "transaction-log", params: { id: id } });
    },
    editEmployeeInfo(emp) {
      // this.edit = true;
      this.infoToBeEdited = emp;
      this.editEmployee();
    },
    modal() {
      this.$store.dispatch("modals/modal", {
        stat: "addEmployee",
        status: !this.addEmployeeStatus,
      });
    },
    editEmployee() {
      this.$store.dispatch("modals/modal", {
        stat: "editEmployee",
        status: !this.editEmployeeStatus,
      });
    },
    paginate(selectedPage) {
      this.$router.push({ query: { page: selectedPage } });
      this.$store.dispatch("cashier/getAllTickets", {
        perPage: 13,
        page: this.page,
      });
    },
    closeTodaysReport(data) {
      this.reportData = data;
      this.$store.dispatch("modals/modal", {
        stat: "reportModal",
        status: !this.reportModalStatus,
      });
      // this.$store.dispatch("branchEmployees/closeTodaysReport", id);
    },
    searchEmployee() {
      this.$store.dispatch("branchEmployees/searchEmployee", this.search);
    },
    toggle(id, status) {
      // console.log("id: ", id);
      this.mode = !status;
      this.$store.dispatch("branches/updateEmployeeByProxima", id);
      // this.$store.dispatch("branchEmployees/activateEmployeeAccount", id);
    },
  },
};
</script>

<style></style>
