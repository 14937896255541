import axios from "axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import CryptoJS from "crypto-js";
const key = "itain'tbeingalone";
const API_URL = process.env.VUE_APP_API_BASE_URL;

function res() {
  // let tk_value = localStorage.getItem("token");
  const encryptedToken = localStorage.getItem("encryptedDevTeamToken");
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, key);
  const tk_value = decryptedBytes.toString(CryptoJS.enc.Utf8);
  const instance = axios.create({
    baseURL: `${API_URL}/api/v1`,
    timeout: 20000,
    headers: {
      authorization: "Bearer " + tk_value,
    },
  });
  instance.interceptors.request.use((config) => {
    NProgress.start();
    return config;
  });
  instance.interceptors.response.use(
    (response) => {
      NProgress.done();
      return response;
    },
    (error) => {
      NProgress.done();
      return Promise.reject(error);
    }
  );

  // console.log("response from axios: ", response);
  return instance;
}

export default {
  devTeamLogin(formdata) {
    return res().post("users/devTeam/login", formdata);
  },
  devTeamSignup(formdata) {
    return res().post("users/devTeam/signup", formdata);
  },
  getAllBranches() {
    return res().get("/agents/");
  },
  countDocument() {
    return res().get("/agents/countDocuments");
  },
  getMessages() {
    return res().get("/comments");
  },
  countMessages() {
    return res().get("/comments/dev/countDocuments");
  },
  getBranchById(id) {
    return res().get(`/agents/${id}`);
  },
  countTicketOfBranches(id) {
    return res().get(`/tickets/devTeam/countAgentUnreportedTickets/${id}`);
  },
  getEmployeeTransaction(payload) {
    return res().get(
      `/reports/devTeam/employee/${payload.id}?sort=-createdAt&limit=${payload.perPage}&page=${payload.page}`
    );
  },
  getEmployeeTransactionCount(id) {
    return res().get(`/reports/devTeam/employee/countDocuments/${id}`);
  },
  getBranchTicketById(id, perPage, page) {
    return res().get(
      `/tickets/devTeam/unreportedAgentTickets/${id}?sort=-createdAt&limit=${perPage}&page=${page}`
    );
  },
  getBranchEmployeesById(id) {
    return res().get(`/devTeam/employee/${id}`);
  },
  searchGame(id, search) {
    return res().get(
      `/gameResults/devTeam/search/agentGameResult/${id}?q=${search}`
    );
  },
  searchTicket(id, search) {
    return res().get(
      `/gameResults/devTeam/search/agentGameResult/${id}?q=${search}`
    );
  },
  getBranchEmployees(id) {
    return res().get(`/agents/employees/${id}`);
  },
  getSingleBranch(id) {
    return res().get(`/agents/${id}`);
  },
  searchBranch(search) {
    return res().get(`/agents/search/${search}`);
  },
  // Post Requests
  createBranch(payload) {
    return res().post("/agents/signup", payload);
  },
  createBranchEmployee(payload) {
    return res().post("/branchEmployees/dev/signup", payload);
  },
  // Patch Request
  activateBranchAccount(id) {
    // console.log("iddddddddddd: ", id);
    return res().patch(`/agents/changeActiveStatus/${id}`);
  },
  toggleSafety(id) {
    // console.log("iddddddddddd: ", id);
    return res().patch(`/agents/changeSafeModeStatus/${id}`);
  },
  toggleAutoSafety(id) {
    // console.log("iddddddddddd: ", id);
    return res().patch(`/agents/changeAutoSafeModeStatus/${id}`);
  },
  updateBranchByProxima({ id, payload }) {
    return res()
      .patch(`/agents/${id}`, payload)
      .catch((error) => {
        if (error.code === "ECONNABORTED") {
          networkIssue();
        }
      });
  },
  updateEmployeeByProxima(id) {
    return res().patch(`/branchEmployees/dev/changeActiveStatus/${id}`);
  },
  updateEmployeeProfileByProxima(id, payload) {
    return res().patch(`/branchEmployees/devTeam/${id}`, payload);
  },
  updateEmployeePasswordByProxima(data) {
    // console.log("data in axios: ", data);
    return res().patch(
      `/branchEmployees/devTeam/updateEmployeePassword/${data.id}`,
      data.payload
    );
  },
  // updateMyBranchProfile(payload) {
  //   return res().patch("/agents/updateProfile", payload);
  // },
  // updateMyPassword(payload) {
  //   return res().patch("/agents/updateMyPassword/", payload);
  // },
  // updateMyProfileByProxima(id) {
  //   return res().patch(`/agents/updateProfile/${id}`);
  // },
  getAllReport() {
    return res().get("/getAllReports/");
  },
  getCount() {
    return res().get("/getCount/");
  },
  getProfit() {
    return res().get("/tickets/profit/");
  },

  getPaidTickets() {
    return res().get("/tickets/paidTickets/");
  },
  getUserById(id) {
    return res().get(`/users/${id}`);
  },
  getWonTickets() {
    return res().get("tickets/wonTickets");
  },
  getLostTickets() {
    return res().get("tickets/lostTickets");
  },
  todayReport() {
    return res().get("tickets/proxima/todayReport");
  },
  getAllTimeReport() {
    return res().get("tickets/proxima/allTimeReport");
  },
  getTicketConfig() {
    return res().get("/getTicketConfig");
  },
  getMe() {
    return res().get("/devTeam/profile");
  },
  updateMyProfile(payload) {
    return res().patch("/cahier/updateProfile/", payload);
  },
  updateMyPassword(payload) {
    return res().patch("/cahier/updateMyPassword/", payload);
  },
  //Post Request
  createTicketConfig(formdata) {
    return res().post("/tickets/config/", formdata);
  },

  //////////////////////////////////////////////
  getSoftwareConfiguration() {
    return res().post("/softwareConfiguration/", formdata);
  },
  setSoftwareConfiguration(formdata) {
    return res().post("/softwareConfiguration/", formdata);
  },
};
