/* eslint-disable */
import Vue from "vue";
import AxiosService from "../../services/AxiosServices";

export const namespaced = true;
export const state = {
  users: [],
  userById: [],
  userrole: "",
};
export const mutations = {
  GET_USER(state, users) {
    state.users = users;
  },
  SET_ROLE(state, userrole) {
    state.userrole = userrole;
  },
  GET_USER_BY_PROFILE(state, userbyprofile) {
    state.userbyprofile = userbyprofile;
  },
  // GET_USER_ROLE(state, userRole) {
  //     state.userRole = userRole
  // },
  SET_USER_ID(state, filteredUserId) {
    state.filteredUserId = filteredUserId;
  },
  REMOVING_USER_INFOS(state, userRole) {
    state.userRole = userRole;
  },
};

export const actions = {
  getAllUsers({ commit }) {
    AxiosService.getAllUsers()
      .then((res) => {
        commit("GET_USER", res.data.user);
        // console.log("res from users", res);
      })
      .catch((error) => {});
  },
  getEmployeeById({ state }, id) {
    AxiosService.getEmployeeById(id)
      .then((res) => {
        state.userById = res.data;
      })
      .catch((error) => {});
  },
  //Post Request
  createUser({ commit, dispatch }, formdata) {
    return AxiosService.Adduser(formdata)
      .then((res) => {
        AxiosService.getAllUsers().then((res) => {
          commit("GET_USER", res.data.user);
          // console.log("res from users", res);
        });
        const notification = {
          type: "success",
          message: "user created successsfuly!",
        };
        dispatch("notification/add", notification, { root: true });
        return res;
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: error.response.data.message,
        };
        dispatch("notification/add", notification, { root: true });
        return false;
      });
  },
  //Patch Requests
  updateUserInfo({ commit, dispatch }, data) {
    AxiosService.updateUserInfo(state.filteredUserId, data)
      .then((res) => {
        AxiosService.getAllUsers().then((res) => {
          commit("GET_USER", res.data.user);
          // console.log("res from users", res);
        });
        const notification = {
          type: "success",
          message: "user info updated successsfuly!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: error.response.data.message,
        };
        dispatch("notification/add", notification, { root: true });
      });
  },
  updateMyProfile({ commit, dispatch }, data) {
    return AxiosService.updateMyProfile(data)
      .then((res) => {
        AxiosService.getAllUsers().then((res) => {
          commit("GET_USER", res.data.user);
          // console.log("res from users", res);
        });
        const notification = {
          type: "success",
          message: "user profile updated successsfully!",
        };
        dispatch("notification/add", notification, { root: true });
        return res;
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: error.response.data.message,
        };
        dispatch("notification/add", notification, { root: true });
        return false;
      });
  },
  updateMyPassword({ commit, dispatch }, data) {
    AxiosService.updateMyPassword(data)
      .then((res) => {
        AxiosService.getAllUsers().then((res) => {
          commit("GET_USER", res.data.user);
          // console.log("res from users", res);
        });
        const notification = {
          type: "success",
          message: "user profile updated successsfully!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: error.response.data.message,
        };
        dispatch("notification/add", notification, { root: true });
      });
  },
  deleteUserbyId({ commit, dispatch }, id) {
    AxiosService.deleteUserbyId(id)
      .then((res) => {
        AxiosService.getAllUsers().then((res) => {
          commit("GET_USER", res.data.user);
          // console.log("res from users", res);
        });
        const notification = {
          type: "success",
          message: "user has been deleted successsfully!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: error.response.data.message,
        };
        dispatch("notification/add", notification, { root: true });
      });
  },
};
export const getters = {
  userRole: (state) => state.userrole,
  filterdId: (state) => state.filteredUserId,
  nightMode: (state) => state.users.nightMode,
  // userRole: state => state.userrole,
};
