/* eslint-disable */
// import Vue from "vue";
export const namespaced = true;
export const state = {
  addEmployee: false,
  editEmployee: false,
  addBranch: false,
  editBranch: false,
  branchDetails: false,
  gameDetails: false,
  printer: false,
  printer56P: false,
};

export const actions = {
  modal({ state }, { stat, status }) {
    state[stat] = status;
    // console.log("fired: ", stat, status);
  },
  closeAll({ state }) {
    Object.keys(state).forEach((key) => {
      state[key] = false;
    });
  },
};
export const getters = {
  addEmployeeStatus: (state) => state.addEmployee,
  editEmployeeStatus: (state) => state.editEmployee,
  gameDetailsStatus: (state) => state.gameDetails,
  addBranchStatus: (state) => state.addBranch,
  editBranchStatus: (state) => state.editBranch,
  printerStatus: (state) => state.printer,
  printerStatus56P: (state) => state.printer56P,
  branchDetailsStatus: (state) => state.branchDetails,
};
