<template>
  <div>
    <div class="bg-darkbg h-full my-2 rounded-xl overflow-x-auto">
      <table class="table-auto w-full text-sm">
        <thead class="sticky">
          <tr>
            <th
              class="border-b border-b-gray-700 text-start px-4 font-light py-2"
            >
              #
            </th>
            <th
              v-for="(head, i) in headers"
              :key="i"
              class="border-b border-b-gray-700 text-center py-2 font-light"
            >
              {{ head }}
            </th>
            <!-- <th class="border-b border-b-gray-700 font-light py-2">Action</th> -->
            <th class="border-b border-b-gray-700 font-light py-2">Details</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(content, index) in contents"
            :key="index"
            class="text-gray-400"
          >
            <td class="px-4 text-start py-2">{{ index + 1 }}</td>
            <td
              v-for="(item, i) in items"
              :key="i"
              :style="{
                color:
                  getNestedValue(content, item.path) < 0
                    ? '#ff0000'
                    : 'inherit',
              }"
              class="py-2 text-center align-top"
            >
              {{ getNestedValue(content, item.path) }}
            </td>
            <!-- <td
              v-if="toggle && toggle.length > 0"
              v-for="(t, index) in toggle"
              :key="index"
              class="px-4 py-2 text-center align-top"
            >
              <label class="relative inline-flex items-center cursor-pointer">
                <input
                  @click="t.handle(content)"
                  type="checkbox"
                  value=""
                  class="sr-only peer"
                />
                <div
                  class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                ></div>
              </label>
            </td>
            <td class="px-4 py-2 text-center align-top">
              <BaseButton @click="handelEdit(content)">
                <img class="w-4 h-4" src="../../assets/icons/edit.svg" alt="" />
              </BaseButton>
            </td> -->
            <td
              class="px-4 text-center py-2 align-top"
              @click="handelDetails(content)"
            >
              More
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    contents: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    toggle: [
      { label: "Auto Safety", handle: "fetchSomething" },
      { label: "Safety", handle: "fetchSomething" },
    ],
    rules: [
      {
        edit: {
          type: Boolean,
          default: false,
        },
        details: {
          type: Boolean,
          default: false,
        },
        toggle: {
          type: Boolean,
          default: false,
        },
      },
    ],
  },
  methods: {
    // getNestedValue(obj, path,item) {
    //   const paths = path.split(".");
    //   let value = obj;
    //   for (let i = 0; i < paths.length; i++) {
    //     value = value[paths[i]];
    //     if (value === undefined) {
    //       if (value === Number && item.class) {
    //         if (value > 0 ) {
    //           // make test red
    //         }
    //         else {
    //           // do nothing
    //         }
    //       }
    //       break;
    //     }
    //   }
    //   return value;
    // },
    // getNestedValue(obj, path) {
    //   const paths = path.split(".");
    //   let value = obj;
    //   for (let i = 0; i < paths.length; i++) {
    //     value = value[paths[i]];
    //     if (value === undefined) {
    //       break;
    //     }
    //   }
    //   return value;
    // },
    getNestedValue(obj, path, item) {
      const paths = path.split(".");
      let value = obj;
      for (let i = 0; i < paths.length; i++) {
        value = value[paths[i]];
        if (value === undefined) {
          break;
        }
      }

      if (typeof value === "number") {
        return Number(value); // Keep the value as is
      }

      return value;
    },
    handelEdit() {
      this.$parent.handelEdit();
    },
    getLocalizedNumber(number) {
      return number.toLocaleString();
    },
    handelDetails(content) {
      this.$parent.handelDetails(content);
    },
  },
};
</script>

<style></style>
