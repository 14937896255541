import Vue from "vue";
// import store from "../store/store";
import VueRouter from "vue-router";

// Error
import ResetPassword from "@/components/Authentication/ResetPassword.vue";
import ResetPasswordByLink from "../components/Authentication/ResetPasswordByLink.vue";
import NotFound from "../Error/NotFound.vue";
import NetworkIssue from "../Error/NetworkIssue.vue";
import Admin from "../views/Admin/Admin.vue";
import Login from "../components/Authentication/Login.vue";
import ForgotPassword from "../components/Authentication/ForgetPassword.vue";
import Branches from "../views/Admin/Branches.vue";
import BranchesConfig from "../views/Admin/BranchesConfig.vue";
import TransactionLog from "../views/Admin/TransactionLog.vue";
import BranchesDetails from "../views/Admin/BranchDetails.vue";
import Config from "../views/Admin/Config.vue";
import Dashboard from "../views/Admin/Dashboard.vue";
import Messages from "../views/Admin/Messages.vue";

const preventRoute = {
  beforeEnter: (to, from, next) => {
    if (localStorage.getItem("encryptedDevTeamToken")) {
      next();
    } else {
      next("/404");
    }
  },
};
const requireAuth = (to, from, next) => {
  const token = localStorage.getItem("encryptedDevTeamToken");
  if (token) {
    next("/Dashboard");
  } else {
    if (to.path === "/") {
      // check if current route is the same as destination route
      next();
    } else {
      next("/");
    }
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/404",
    name: "404",
    component: NotFound,
    meta: {
      title: "NotFound",
    },
  },
  {
    path: "/network-issue",
    name: "NetworkIssue",
    component: NetworkIssue,
    meta: {
      title: "Network issue",
    },
  },
  {
    path: "/forgotPassword",
    name: "forgot-password",
    component: ForgotPassword,
    meta: {
      title: "ForgotPassword",
    },
  },
  {
    path: "/ResetPassword",
    name: "reset-password",
    component: ResetPassword,
    meta: {
      title: "ResetPassword",
    },
  },
  {
    path: "/ResetPasswordByLink/:id",
    name: "reset-password-by-link",
    component: ResetPasswordByLink,
    meta: {
      title: "ResetPasswordByLink",
    },
  },
  {
    path: "*",
    redirect: { name: "404" },
  },
  // {
  //   path: "/",
  //   name: "keno",
  //   component: Keno,
  //   meta: {
  //     title: "Keno",
  //   },
  // },
  {
    beforeEnter: requireAuth,
    path: "/",
    name: "log-in",
    component: Login,
    meta: {
      title: "Login",
    },
  },

  // {
  //   path: "/forgotPassword",
  //   name: "forgot-password",
  //   component: ForgotPassword,
  //   meta: {
  //     title: "ForgotPassword",
  //   },
  // },
  {
    path: "/Admin",
    name: "admin",
    component: Admin,
    ...preventRoute,
    children: [
      {
        path: "/Dashboard",
        name: "dashboard",
        component: Dashboard,
        ...preventRoute,
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "/BranchesConfig",
        name: "branch-config",
        component: BranchesConfig,
        meta: {
          title: "BranchesConfig",
        },
        ...preventRoute,
      },
      {
        path: "/Messages",
        name: "messages",
        component: Messages,
        meta: {
          title: "Messages",
        },
        ...preventRoute,
      },
      {
        path: "/TransactionLog/:id",
        name: "transaction-log",
        component: TransactionLog,
        meta: {
          title: "TransactionLog",
        },
        ...preventRoute,
      },
      {
        path: "/Branches",
        name: "branches",
        component: Branches,
        meta: { title: "Branches" },
        ...preventRoute,
        // children: [
        //   {
        //     path: "/Branches/BranchDashboard",
        //     name: "branch-dashboard",
        //     component: BranchDashboard,
        //     ...preventRoute,
        //     meta: { title: "Branch Dashboard" },
        //   },
        //   {
        //     path: "/Branches/BranchEmployees",
        //     name: "branch-employees",
        //     component: BranchEmployees,
        //     ...preventRoute,
        //     meta: { title: "Branch Employees" },
        //   },
        //   {
        //     path: "/Branches/BranchTickets",
        //     name: "branch-tickets",
        //     component: BranchTickets,
        //     ...preventRoute,
        //     meta: { title: "Branch Tickets" },
        //   },
        // ],
      },
      {
        path: "/Config",
        name: "config",
        component: Config,
        ...preventRoute,
        meta: {
          title: "Config",
        },
      },
      {
        path: "/BranchDetails/:id",
        name: "branch-details",
        component: BranchesDetails,
        ...preventRoute,
        meta: {
          title: "BranchDetails",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: () => {
    return {
      x: 0,
      y: 0,
    };
  },
});
router.beforeEach((to, from, next) => {
  document.title = `DevTeam | ${to.meta.title}`;
  next();
});

export default router;
