var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-screen",attrs:{"id":"Myfont"}},[_c('div',{staticClass:"flex justify-center"},[_c('div',{staticClass:"my-12 py-10 bg-darkcard shadow-xs p-3",staticStyle:{"border-radius":"5px"}},[_c('div',{staticClass:"p-2"},[_vm._m(0),_vm._m(1),_c('form',{staticClass:"mt-2 text-white space-y-3 max-w-2xl",staticStyle:{"width":"350px"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"relative"},[_c('BaseInput',{attrs:{"autocomplete":"off","label":"New Password","type":"Password","type":_vm.show ? 'text' : 'password',"inputClass":"bg-darkbg outline-none w-full p-2 rounded "},on:{"blur":function($event){return _vm.$v.formdata.password.$touch()}},model:{value:(_vm.formdata.password),callback:function ($$v) {_vm.$set(_vm.formdata, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formdata.password"}}),(_vm.$v.formdata.password.$error)?_c('div',[(!_vm.$v.formdata.password.required)?_c('p',{staticClass:"text-xs text-red-500",attrs:{"id":"error_label"}},[_vm._v(" password is required! ")]):_vm._e()]):_vm._e(),(_vm.$v.formdata.password.$error)?_c('div',[(!_vm.$v.formdata.password.minLength)?_c('p',{staticClass:"text-xs text-red-600",attrs:{"id":"error_label"}},[_vm._v(" Password must be greater than 6 characters! ")]):_vm._e(),(!_vm.$v.formdata.password.maxLength)?_c('p',{staticClass:"text-xs text-red-600",attrs:{"id":"error_label"}},[_vm._v(" Password must be less than 12 characters! ")]):_vm._e()]):_vm._e(),_c('span',{staticClass:"absolute inset-y-0 left-72 ml-2 grid place-content-center px-4",class:{
                hidden: _vm.show,
                block: !_vm.show,
                'mt-6': !_vm.$v.formdata.password.$error,
              }},[_c('svg',{staticClass:"h-6 w-6 text-1",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 12a3 3 0 11-6 0 3 3 0 016 0z"}}),_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"}})])]),_c('span',{staticClass:"absolute inset-y-0 left-72 ml-2 grid place-content-center px-4",class:{
                block: _vm.show,
                hidden: !_vm.show,
                'mt-6': !_vm.$v.formdata.password.$error,
              }},[_c('img',{staticClass:"text-1 h-8 w-8",attrs:{"src":require("../../assets/eyeslashsvgrepocom.svg"),"alt":"","srcset":""},on:{"click":function($event){_vm.show = !_vm.show}}})])],1),_c('div',{staticClass:"relative"},[_c('BaseInput',{attrs:{"autocomplete":"off","label":"Confirm Password","type":"Password","type":_vm.showConfirm ? 'text' : 'password',"inputClass":"bg-darkbg outline-none w-full p-2 rounded "},on:{"blur":function($event){return _vm.$v.formdata.passwordConfirm.$touch()}},model:{value:(_vm.formdata.passwordConfirm),callback:function ($$v) {_vm.$set(_vm.formdata, "passwordConfirm", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formdata.passwordConfirm"}}),_c('span',{staticClass:"absolute inset-y-0 left-72 ml-2 grid place-content-center px-4",class:{
                hidden: _vm.showConfirm,
                block: !_vm.showConfirm,
                'mt-6': !_vm.$v.formdata.passwordConfirm.$error,
              }},[_c('svg',{staticClass:"h-6 w-6 text-1",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"},on:{"click":function($event){_vm.showConfirm = !_vm.showConfirm}}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 12a3 3 0 11-6 0 3 3 0 016 0z"}}),_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"}})])]),_c('span',{staticClass:"absolute inset-y-0 left-72 ml-2 grid place-content-center px-4",class:{
                block: _vm.showConfirm,
                hidden: !_vm.showConfirm,

                'mt-6': !_vm.$v.formdata.passwordConfirm.$error,
              }},[_c('img',{staticClass:"text-1 h-8 w-8",attrs:{"src":require("../../assets/eyeslashsvgrepocom.svg"),"alt":"","srcset":""},on:{"click":function($event){_vm.showConfirm = !_vm.showConfirm}}})]),(_vm.$v.formdata.passwordConfirm.$error)?_c('div',[(!_vm.$v.formdata.passwordConfirm.sameAs)?_c('p',{staticClass:"text-xs text-red-500",attrs:{"id":"error_label"}},[_vm._v(" Passwords don't match! ")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"flex justify-center"},[_c('button',{attrs:{"disabled":_vm.forgetPasswordRequestSent,"type":"submit","Class":"bg-text flex space-x-3 mt-3 justify-center py-1 w-full text-white"},on:{"click":function($event){return _vm.onSubmit()}}},[(_vm.forgetPasswordRequestSent)?_c('svg',{staticClass:"h-5 w-5 animate-spin text-white",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24"}},[_c('circle',{staticClass:"opacity-25",attrs:{"cx":"12","cy":"12","r":"10","stroke":"currentColor","stroke-width":"4"}}),_c('path',{staticClass:"opacity-75",attrs:{"fill":"currentColor","d":"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"}})]):_vm._e(),_vm._v(" Submit ")])]),(_vm.error)?_c('p',{staticClass:"text-xs font-semibold text-red-700"},[_vm._v(" please fill out the forms correctly! ")]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-center"},[_c('img',{staticClass:"h-20",attrs:{"src":require("../../assets/Login.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-4 flex justify-center space-y-2"},[_c('p',{staticClass:"text-2xl text-blue-700"},[_vm._v("Reset Password")])])
}]

export { render, staticRenderFns }