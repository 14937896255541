/* eslint-disable */
// import Vue from "vue";
import ErrorHandler from "@/services/ErrorHandler";
import AxiosService from "../../services/AxiosServices";
import router from "@/router";

export const namespaced = true;
export const state = {
  employees: [],
  singleEmployee: [],
  searchEmployee: [],
  me: [],
  trueRole: "",
};
export const mutations = {};

export const actions = {
  // get Requests
  // getMe({ state }) {
  //   return AxiosService.getMeBranchEmploye().then((res) => {
  // console.log('response" ', res.data.user.role);
  //     state.me = res.data.user;
  //     state.trueRole = res.data.user.role;
  //     return res.data.user.role;
  //   })
  // },
  getAllEmployee({ state, dispatch }) {
    return AxiosService.getAllEmployee()
      .then((res) => {
        state.employees = res.data.user;
        // console.log("branchEmployees :", res.data.user);
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  getEmployeeTransaction({ state, dispatch }, payload) {
    return AxiosService.getEmployeeTransaction(payload)
      .then((res) => {
        // state.Transaction = res.data.doc;
        // console.log("Transaction: ", res.data.doc);
        return res.data.doc;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  getEmployeeTransactionCount({ state, dispatch }, id) {
    return AxiosService.getEmployeeTransactionCount(id)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  getEmployeeById({ state, dispatch }, id) {
    return AxiosService.getEmployeeById(id)
      .then((res) => {
        state.singleEmployee = res.data.user;
        return true;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  searchEmployee({ state, dispatch }, search) {
    AxiosService.searchEmployee(search)
      .then((res) => {
        let Result = res.data.bet;
        state.searchEmployee.push({ Result });
        // console.log("search", res.data);
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  // Post Request
  createEmployee({ state, dispatch }, payload) {
    return AxiosService.createEmployee(payload)
      .then((res) => {
        AxiosService.getAllEmployee().then((res) => {
          state.employees = res.data.user;
          // console.log("branchEmployees :", res.data.user);
        });
        const notification = {
          type: "success",
          message: "You have created employee successsfully!",
        };
        dispatch("notification/add", notification, { root: true });
        return true;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  //   Patch
  activateEmployeeAccount({ dispatch }, payload) {
    AxiosService.activateEmployeeAccount(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  forgotEmployeePassowrd({ dispatch }, payload) {
    AxiosService.forgotEmployeePassowrd(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  ResetEmployeePassword({ dispatch }, payload) {
    AxiosService.ResetEmployeePassword(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  updateMyProfileEmployee({ dispatch }, payload) {
    AxiosService.updateMyProfileEmployee(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  updateMyPasswordEmployee({ dispatch }, payload) {
    AxiosService.updateMyPasswordEmployee(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  updateMyProfileByProxima({ dispatch }, id) {
    AxiosService.updateMyProfileByProxima(id)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
};
export const getters = {};
