<template>
  <div id="Myfont" class="bg-darkbg h-screen">
    <DevTeamNavBar />
    <router-view />
  </div>
</template>
<script setup>
// import { RouterLink, RouterView } from "vue-router";
</script>
<script>
import DevTeamNavBar from "../../components/DevTeamNavBar.vue";

export default {
  components: {
    DevTeamNavBar,
  },
};
</script>

<style>
#Myfont {
  font-family: PTSans;
  /* src: local('mons'), url('./assets/Fonts/') format('truetype');s */
}
@font-face {
  font-family: "PTSans";
  src: local("PTSans"),
    url("../../assets/Fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: 100;
  font-size: xx-large;
}
</style>
