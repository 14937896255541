/* eslint-disable */
export const namespaced = true;
export const state = {
  sideBarStatus: "dashboard",
  sideBar: [
    {
      label: "Branch Dashboard",
      to: "dashboard",
    },
    {
      label: "Branch Ticket",
      to: "tickets",
    },
    {
      label: "Branch Employees",
      to: "employees",
    },
  ],
};
export const mutations = {};
export const actions = {
  setStatus({ state }, payload) {
    state.sideBarStatus = payload.to;
  },
};
export const getters = {
  sideBarStatus: (state) => state.sideBarStatus,
};
