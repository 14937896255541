<template>
  <div>
    <div class="w-8 h-full">
      <div class="space-y-8 pt-5">
        <p
          v-for="(side, i) in sideBar"
          :key="i"
          :class="{
            'bg-blue-300 rounded-md py-1 text-text': sideBarStatus == side.to,
            slideUp: sideBarStatus == side.to,
          }"
          class="text-gray-300 text-center py-2 text-sm cursor-pointer"
          @click="routerDotPush(side)"
        >
          <i v-if="side.to == 'dashboard'" class="fa fa-dashboard fa-lg"></i>
          <i v-if="side.to == 'employees'" class="fa fa-user fa-lg"></i>
          <i v-if="side.to == 'tickets'" class="fa fa-ticket fa-lg"></i>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("status", ["sideBar"]),
    ...mapGetters("status", ["sideBarStatus"]),
  },
  methods: {
    routerDotPush(side) {
      this.$store.dispatch("status/setStatus", side);
    },
  },
};
</script>

<style>
.indicator {
  transition: background-color 0.3s ease;
}
.slideUp {
  animation: slide-up 0.3s ease;
}

.slideDown {
  animation: slide-down 0.3s ease;
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-50%);
    opacity: 0;
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
