<template>
  <div class="w-screen">
    <div class="flex justify-center py-4" v-if="noConnection">
      <network-issue />
    </div>
    <!-- <div class="flex w-full justify-center py-16" v-if="spinner">
      <spinner />
    </div> -->
    <div v-if="!noConnection" class="m-5 text-white flex">
      <div class="space-y-3 w-full">
        <div class="h-auto bg-darkcard rounded-lg p-4">
          <p class="text-white">Branches</p>
          <div class="">
            <apexchart
              class="text-text"
              width="100%"
              height="350"
              type="area"
              :options="options"
              :series="series"
            ></apexchart>
          </div>
        </div>
        <!-- {{ report }} -->
        <!-- {{ allTimeReport }} -->
        <div
          class="text flex justify-around p-5 text-sm rounded-lg bg-darkcard"
        >
          <div
            class="bg-darkbg space-y-2 text-base rounded-lg p-3 w-48 text-center"
          >
            <div>Total Branches</div>
            <div class="text-indigo-500 space-x-3 text-center text-xl">
              <div>{{ animateTotalTickets.toLocaleString() }}</div>
            </div>
          </div>
          <!-- <div
            class="bg-darkbg space-y-2 text-base rounded-lg p-3 w-48 text-center"
          >
            <div>Total Branches</div>
            <div class="text-indigo-500 space-x-3 text-center text-xl">
              <div>{{ animateTotalBranches.toLocaleString() }}</div>
            </div>
          </div> -->
          <!-- <div
            class="bg-darkbg space-y-2 text-base rounded-lg p-3 w-48 text-center"
          >
            <div>Total Employees</div>
            <div class="text-indigo-500 space-x-3 text-center text-xl">
              <div>{{ animateTotalEmployees.toLocaleString() }}</div>
            </div>
          </div> -->
          <div
            class="bg-darkbg space-y-2 text-base rounded-lg p-3 w-48 text-center"
          >
            <div>Total Gross income</div>
            <div class="text-yellow-500 space-x-3 text-center text-xl">
              <div>{{ animateTotalGrossIncome.toLocaleString() }}</div>
            </div>
          </div>
          <div
            class="bg-darkbg space-y-2 text-base rounded-lg p-3 w-48 text-center"
          >
            <div>Total Payouts</div>
            <div class="text-red-400 space-x-3 text-center text-xl">
              <div>{{ animateTotalPayouts.toLocaleString() }}</div>
            </div>
          </div>
          <div
            class="bg-darkbg space-y-2 text-base rounded-lg p-3 w-48 text-center"
          >
            <div>Total Profit</div>
            <div class="text-green-400 space-x-3 text-center text-xl">
              <div>{{ animateTotalProfit.toLocaleString() }}</div>
            </div>
          </div>

          <!-- {{ report }} -->
        </div>
      </div>
      <div class="object-contain">
        <!-- <kenoAdmin /> -->
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { mapState } from "vuex";
import NetworkIssue from "@/Error/NetworkIssue.vue";
// import spinner from "@/components/spinner.vue";
export default {
  components: {
    // KenoAdmin,
    // ApexCharts,
    // spinner,
    NetworkIssue,
  },
  data() {
    return {
      noConnection: false,
      // spinner: false,
      totalGrossIncome: 0,
      animateTotalGrossIncome: (0).toFixed(0),
      totalPayouts: 0,
      animateTotalPayouts: (0).toFixed(0),
      totalTickets: 0,
      animateTotalTickets: (0).toFixed(0),
      totalProfit: 0,
      animateTotalProfit: (0).toFixed(0),
      totalBranches: 0,
      animateTotalBranches: (0).toFixed(0),
      totalEmployees: 0,
      animateTotalEmployees: (0).toFixed(0),
      options: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: ["0", "ticket Sale", "Total income", "Payout", "Profit"],
        },
        theme: {
          mode: "dark",
          palette: "palette6",
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "dark",
            shadeIntensity: 1,
          },
        },
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 3,
        },
      },

      series: [{ data: [] }],
    };
  },
  watch: {
    totalGrossIncome(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalGrossIncome: newValue,
      });
    },
    totalPayouts(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalPayouts: newValue,
      });
    },
    totalTickets(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalTickets: newValue,
      });
    },
    totalProfit(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalProfit: newValue,
      });
    },
    totalEmployees(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalEmployees: newValue,
      });
    },
    totalBranches(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalBranches: newValue,
      });
    },
  },
  created() {
    // this.spinner = true;
    // this.$store.dispatch("branches/getAllTimeReport").then((response) => {
    //   console.log("response:", response);
    //   if (response) {
    //     this.spinner = false;
    //     this.totalGrossIncome = response.data.totalPlacedMoney;
    //     this.totalPayouts = response.data.totalPrizeMoney;
    //     this.totalProfit = response.data.totalProfit;
    //     this.totalTickets = response.data.result;
    //     this.totalBranches = response.data.agents;
    //     this.totalEmployees = response.data.employees;
    //   } else {
    //     this.spinner = false;
    //     this.noConnection = true;
    //   }
    // });
    this.$store.dispatch("branches/getTodayReports").then((response) => {
      // console.log("response: Today", response);
      if (response) {
        // this.spinner = false;
        this.totalGrossIncome = response.data.totalPlacedMoney;
        this.totalPayouts = response.data.totalPayoutMoney;
        this.totalProfit = response.data.totalProfit;
        this.totalTickets = response.data.result;
        // this.totalBranches = response.data.agents;
        // this.totalEmployees = response.data.employees;
        this.series = [
          {
            data: [
              "0",
              response.data.result,
              response.data.totalPlacedMoney,
              response.data.totalPayoutMoney,
              response.data.totalProfit,
            ],
          },
        ];
      }
    });
  },
  computed: {
    ...mapState("branches", ["report"]),
    ...mapState("branches", ["allTimeReport"]),
  },
  // Ticket sales of last 7 days
  // Total Growth income of last 7 days
  // Payouts  of last 7 days
  // Profit sales of last 7 days
  // Todays Ticket sales
  // Todays Growth income sales
  // Todays Payouts sales
  // Todays Profit sales
};
</script>

<style></style>
