<template>
  <div class="p-8">
    <div class="flex justify-between mx-auto mb-16">
      <p class="text-gray-400">Add Employee</p>
      <img
        @click="close()"
        class="cursor-pointer"
        src="../../../assets/icons/x.svg"
        alt=""
        srcset=""
      />
    </div>
    <form
      @submit.prevent="onSubmit"
      class="w-full lg:grid lg:grid-cols-2 gap-5"
    >
      <div>
        <BaseInput
          label="name"
          inputClass="bg-darkbg outline-none w-full p-2 rounded "
          v-model.trim="formdata.employeeName"
          @blur="$v.formdata.employeeName.$touch()"
        />
        <div v-if="$v.formdata.employeeName.$error">
          <p
            class="text-xs text-red-600"
            v-if="!$v.formdata.employeeName.required"
            id="error_label"
          >
            Employee name is required!
          </p>
        </div>
      </div>
      <div>
        <BaseInput
          label="Email"
          type="Email"
          inputClass="bg-darkbg outline-none w-full p-2 rounded "
          v-model.trim="formdata.email"
          @blur="$v.formdata.email.$touch()"
        />
        <div v-if="$v.formdata.email.$error">
          <p
            class="text-xs text-red-500"
            v-if="!$v.formdata.email.required"
            id="error_label"
          >
            email is required!
          </p>
        </div>
        <div v-if="$v.formdata.email.$error && $v.formdata.email.required">
          <p
            class="text-xs text-red-500"
            v-if="!$v.formdata.email.email"
            id="error_label"
          >
            invalid email!
          </p>
        </div>
      </div>
      <div>
        <BaseInput
          autocomplete="off"
          label="Phone Number"
          inputClass="bg-darkbg outline-none w-full p-2 rounded "
          v-model.trim="formdata.phoneNumber"
          type="number"
          @blur="$v.formdata.phoneNumber.$touch()"
        />
        <div v-if="$v.formdata.phoneNumber.$error">
          <p
            class="text-xs text-red-500"
            v-if="!$v.formdata.phoneNumber.required"
            id="error_label"
          >
            Phone number is required!
          </p>
        </div>
      </div>
      <div class="inline-block relative w-full">
        <p>Role</p>
        <select
          class="bg-darkbg outline-none w-full p-2 rounded"
          style=""
          v-model.trim="formdata.role"
          @blur="$v.formdata.role.$touch()"
        >
          <option class="bg-darkcard rounded-none" value="payoutGiver">
            Payout Giver
          </option>
          <option class="bg-darkcard rounded-none" value="cashier">
            Cashier
          </option>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <!-- <i class="fa fa-angle-down"></i> -->
        </div>
        <div v-if="$v.formdata.role.$error">
          <p
            class="text-xs text-red-500"
            v-if="!$v.formdata.role.required"
            id="error_label"
          >
            Role is required!
          </p>
        </div>
      </div>
      <div>
        <BaseInput
          autocomplete="off"
          :passwordInput="true"
          label="Password"
          inputClass="bg-darkbg outline-none w-full p-2 rounded "
          v-model.trim="formdata.password"
          @blur="$v.formdata.password.$touch()"
        />

        <div v-if="$v.formdata.password.$error">
          <p
            class="text-xs text-red-500"
            v-if="!$v.formdata.password.required"
            id="error_label"
          >
            password is required!
          </p>
        </div>
        <div v-if="$v.formdata.password.$error">
          <p
            class="text-xs text-red-600"
            v-if="!$v.formdata.password.minLength"
            id="error_label"
          >
            Password must be greater than 6 characters!
          </p>
          <p
            class="text-xs text-red-600"
            v-if="!$v.formdata.password.maxLength"
            id="error_label"
          >
            Password must be less than 12 characters!
          </p>
        </div>
      </div>
      <div>
        <BaseInput
          autocomplete="off"
          class=""
          :passwordInput="true"
          inputClass="bg-darkbg outline-none w-full p-2 rounded "
          label="Confirm Password"
          v-model.trim="formdata.passwordConfirm"
          @blur="$v.formdata.passwordConfirm.$touch()"
        />

        <div v-if="$v.formdata.passwordConfirm.$error">
          <p
            class="text-xs text-red-500"
            v-if="!$v.formdata.passwordConfirm.sameAs"
            id="error_label"
          >
            Passwords don't match!
          </p>
        </div>
      </div>
    </form>
    <div class="w-full">
      <BaseButton
        buttonClass="w-full cursor-pointer flex text-base justify-center mt-5 p-2 bg-text"
        @click="onSubmit"
        >Register</BaseButton
      >
      <p v-if="error" class="text-xs text-red-600">
        please fill out all the form correctly!
      </p>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  maxLength,
  minLength,
  sameAs,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      error: false,
      status: false,
      show: false,
      showConfirm: false,
      formdata: {
        email: "hello@gmail.com",
        password: "hello1234",
        employeeName: "hello",
        phoneNumber: "0909090909",
        passwordConfirm: "hello1234",
        role: "cashier",
      },
    };
  },
  validations: {
    formdata: {
      email: { required, email },
      employeeName: { required },
      phoneNumber: { required },
      passwordConfirm: { required, passwordConfirm: sameAs("password") },
      password: { required, minLength: minLength(8), maxLength: maxLength(12) },
      role: { required },
    },
  },
  computed: {
    ...mapGetters("modals", ["addEmployeeStatus"]),
  },
  methods: {
    close() {
      this.$store.dispatch("modals/closeAll");
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("branches/createBranchEmployee", this.formdata)
          .then((response) => {
            // console.log("response", response);
            if (response && !response.error) {
              this.$parent.$parent.fetchBranchEmployees(
                this.$parent.$parent.id
              );
              this.$store.dispatch("modals/closeAll");
            }
          });
      } else {
        // console.log("error");
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 5000);
      }
    },
  },
};
</script>

<style></style>
