/* eslint-disable */
// import Vue from "vue";
import ErrorHandler from "@/services/ErrorHandler";
import AxiosService from "../../services/AxiosServices";
import router from "@/router";

export const namespaced = true;
export const state = {
  activeAgentForDetails: "",
  Branches: [],
  progressStatus: {
    ticket: false,
    employee: false,
    dashboard: false,
  },
  BranchesForDetail: {
    aId: 0,
    branchName: [],
    allBranches: [],
    agentName: "",
    currentBranch: [],
    loaded: false,
  },
  singleBranch: [],
  // singleBranch: [],
  searchBranch: [],
  branchEmployees: [],
  report: [],
  allTimeReport: [],
  me: [],
  totalBranches: 0,
  // listOfBranches: [],
};
export const mutations = {};

export const actions = {
  getTodayReports({ state }) {
    return AxiosService.todayReport().then((res) => {
      state.report = res.data;
      // console.log("Tickets: ", res.data);
      return res;
    });
  },
  getAllTimeReport({ dispatch, state }) {
    return AxiosService.getAllTimeReport()
      .then((res) => {
        state.allTimeReport = res.data;
        // console.log("Tickets: ", res.data);
        return res;
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  },
  activeName({ state }, payload) {
    state.activeAgentForDetails = payload;
    return true;
    // });
  },
  countDocument({ state }) {
    return AxiosService.countDocument().then((res) => {
      state.totalBranches = res.data.result;
      // console.log("count: ", state.totalBranches);
      return res.data.result;
    });
  },

  assignBranch({ state }, payload) {
    // console.log("payload: ", payload);
    const index = state.BranchesForDetail.allBranches.findIndex((element) => {
      // console.log("element:", element);
      return String(element.agentName) === String(payload);
    });

    if (index !== -1) {
      state.BranchesForDetail.currentBranch =
        state.BranchesForDetail.allBranches[index];
      // console.log(" state: ", state.BranchesForDetail.allBranches[index]);
      // console.log(" current: ", state.BranchesForDetail.currentBranch);
      state.BranchesForDetail.aId = state.BranchesForDetail.currentBranch.id;
    }
    // console.log("index: ", index);
  },

  getAllBranchesForDetail({ dispatch, state }) {
    return AxiosService.getAllBranches()
      .then((res) => {
        state.BranchesForDetail.aId = res.data.user[0].id;
        state.BranchesForDetail.agentName = res.data.user[0].agentName;
        // console.log("BranchesForDetail: ", state.BranchesForDetail);
        return res.data.user;
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  },

  getAllBranches({ dispatch, state }) {
    return AxiosService.getAllBranches()
      .then((res) => {
        state.Branches = res.data.user;
        // console.log("state branch: ", state.Branches);
        return res.data.user;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        ErrorHandler(error);
      });
  },
  async getBranchById({ state }, id) {
    try {
      const res = await AxiosService.getBranchById(id);
      state.singleBranch = res.data.user;
      return res.data.user;
    } catch (error) {
      ErrorHandler(error);
    }
  },
  async createBranchEmployee({ state }, payload) {
    const newId = await localStorage.getItem("activeAgent");
    let body = {
      agentId: newId,
      ...payload,
    };
    // console.log("body :", body);
    try {
      const res = await AxiosService.createBranchEmployee(body);
      // console.log("res: ", res);
      return res.data;
    } catch (error) {
      ErrorHandler(error);
    }
  },
  async getMessages({ state }) {
    try {
      const res = await AxiosService.getMessages();
      // console.log("res: ", res);
      return res.data;
    } catch (error) {
      ErrorHandler(error);
    }
  },
  async countMessages({ state }) {
    try {
      const res = await AxiosService.countMessages();
      // console.log("res: ", res);
      return res.data;
    } catch (error) {}
  },
  async countTicketOfBranches({ state }) {
    const id = await localStorage.getItem("activeAgent");
    return AxiosService.countTicketOfBranches(id).then((res) => {
      return res;
    });
  },
  async getBranchTicketById({ state }, { id, perPage, page }) {
    try {
      const res = await AxiosService.getBranchTicketById(id, perPage, page);
      // state.singleBranch = res.data.user;
      return res.data;
    } catch (error) {
      ErrorHandler(error);
      throw error;
    }
  },
  async fetchGameById({ state }, search) {
    try {
      const id = await localStorage.getItem("activeAgent");
      const res = await AxiosService.searchGame(id, search);
      // console.log("search: ", res);
      return res.data;
    } catch (error) {
      ErrorHandler(error);
      throw error;
    }
  },
  async fetchTicketById({ state }, search) {
    try {
      const newId = await localStorage.getItem("activeAgent");
      const res = await AxiosService.searchTicket(newId, search);
      // console.log("search: ", res);
      return res.data;
    } catch (error) {
      ErrorHandler(error);
      throw error;
    }
  },
  async getBranchEmployeesById({ state }, id) {
    try {
      const newId = await localStorage.getItem("activeAgent");
      const res = await AxiosService.getBranchEmployeesById(id ? id : newId);
      // console.log("search: ", res);
      return res.data;
    } catch (error) {
      ErrorHandler(error);
      throw error;
    }
  },
  async getSingleBranchEmployeesById({ state }, id) {
    try {
      const res = await AxiosService.getBranchEmployeesById(id);
      // console.log("search: ", res);
      return res.data;
    } catch (error) {
      ErrorHandler(error);
      throw error;
    }
  },
  async getBranchEmployees({ state }, id) {
    const newId = await localStorage.getItem("activeAgent");
    return AxiosService.getBranchEmployees(id ? id : newId)
      .then((res) => {
        state.branchEmployees = res.data.user;
        return res.data.user;
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  },
  getSingleBranch({ dispatch, state }, id) {
    AxiosService.getSingleBranch(id)
      .then((res) => {
        state.singleBranch = res.data;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        ErrorHandler(error);
      });
  },

  searchBranch({ dispatch, state }, id) {
    AxiosService.searchBranch(id)
      .then((res) => {
        let Result = res.data.bet;
        state.searchBranch.push({ Result });
        // console.log("search", res.data);
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        ErrorHandler(error);
      });
  },
  // Post Request
  createBranch({ dispatch }, payload) {
    return AxiosService.createBranch(payload)
      .then((res) => {
        AxiosService.getAllBranches().then((res) => {
          state.Branches = res.data.user;
          // console.log("state branch: ", state.Branches);
        });
        const notification = {
          type: "success",
          message: "Branch created successsfully!",
        };
        dispatch("notification/add", notification, { root: true });
        return true;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        ErrorHandler(error);
      });
  },
  //   Patch
  activateBranchAccount({ dispatch }, id) {
    // console.log("iddddddddddd: ", id);
    AxiosService.activateBranchAccount(id)
      .then((res) => {
        AxiosService.getAllBranches().then((res) => {
          state.Branches = res.data.user;
          // console.log("state branch: ", state.Branches);
        });
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        ErrorHandler(error);
      });
  },
  toggleSafety({ dispatch }, id) {
    // console.log("iddddddddddd: ", id);
    AxiosService.toggleSafety(id)
      .then((res) => {
        AxiosService.getAllBranches().then((res) => {
          state.Branches = res.data.user;
          // console.log("state branch: ", state.Branches);
        });
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        ErrorHandler(error);
      });
  },
  toggleAutoSafety({ dispatch }, id) {
    // console.log("iddddddddddd: ", id);
    AxiosService.toggleAutoSafety(id)
      .then((res) => {
        AxiosService.getAllBranches().then((res) => {
          state.Branches = res.data.user;
          // console.log("state branch: ", state.Branches);
        });
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        ErrorHandler(error);
      });
  },
  updateMyProfile({ dispatch }, payload) {
    AxiosService.updateMyProfile(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        ErrorHandler(error);
      });
  },
  updateMyPassword({ dispatch }, payload) {
    AxiosService.updateMyPassword(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        ErrorHandler(error);
      });
  },
  updateBranchByProxima({ dispatch }, { id, payload }) {
    return AxiosService.updateBranchByProxima({ id, payload })
      .then((res) => {
        AxiosService.getAllBranches().then((res) => {
          state.Branches = res.data.user;
          // console.log("state branch: ", state.Branches);
        });
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
        return true;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        ErrorHandler(error);
      });
  },
  updateMyProfileByProxima({ dispatch }, id) {
    AxiosService.updateMyProfileByProxima(id)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        ErrorHandler(error);
      });
  },
  updateEmployeeByProxima({ dispatch }, id) {
    // console.log(id);
    return AxiosService.updateEmployeeByProxima(id)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
        return true;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        ErrorHandler(error);
      });
  },
};
export const getters = {};
