<template>
  <div>
    <div class="ui selection dropdown">
      <input type="hidden" />
      <i class="dropdown icon"></i>
      <div class="default text">State</div>
      <div class="menu">
        <div class="item" data-value="">State</div>
        <div class="item" data-value="AL">Alabama</div>
        <div class="item" data-value="AK">Alaska</div>
        <!-- Add more options here -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.ui.dropdown {
  display: inline-block;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  padding: 0.5em 1em;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.ui.dropdown i.dropdown.icon {
  position: absolute;
  top: 50%;
  right: 0.5em;
  transform: translateY(-50%);
  pointer-events: none;
}

.ui.dropdown .menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 4px;
  padding: 0.5em 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  z-index: 1;
}

.ui.dropdown .menu .item {
  padding: 0.5em 1em;
  cursor: pointer;
}

.ui.dropdown .menu .item:hover {
  background-color: #f9f9f9;
}
</style>
