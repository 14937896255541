<template>
  <div>
    <div class="h-auto bg-darkcard rounded-lg p-4">
      <div class="flex justify-between">
        <p class="text-white">
          Today's Report {{ BranchesForDetail.currentBranch.agentName }}
        </p>
        <!-- <div class="flex justify-center space-x-5">
          <p class="text-white">
            Total Week Report: {{ BranchesForDetail.currentBranch.agentName }}
          </p>
          <p class="text-white">
            Total Proxima's share:
            {{ BranchesForDetail.currentBranch.agentName }}
          </p>
        </div> -->
      </div>

      <!-- {{ BranchesForDetail.currentBranch }} -->
      <div class="">
        <apexchart
          style="color: red"
          class="text-text"
          width="100%"
          height="300"
          type="area"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
      <div>
        <div
          class="text-center w-full space-y-5 lg:space-y-0 lg:flex lg:justify-around justify-center p-5 text-sm rounded-lg bg-darkcard"
        >
          <p class="lg:hidden text-xl">Total Report</p>
          <div class="lg:block flex justify-center">
            <div
              class="bg-darkbg space-y-2 text-base rounded-lg p-3 w-56 text-center"
            >
              <div>Total Tickets Sold</div>
              <div class="text-indigo-500 space-x-3 text-center text-xl">
                <div>{{ animateTotalTickets.toLocaleString() }}</div>
              </div>
            </div>
          </div>

          <div class="lg:block flex justify-center">
            <div
              class="bg-darkbg space-y-2 text-base rounded-lg p-3 w-56 text-center"
            >
              <div>Total Gross income</div>
              <div class="text-yellow-500 space-x-3 text-center text-xl">
                <div>
                  {{ animateTotalGrossIncome.toLocaleString() }}
                </div>
              </div>
            </div>
          </div>
          <div class="lg:block flex justify-center">
            <div
              class="bg-darkbg space-y-2 text-base rounded-lg p-3 w-56 text-center"
            >
              <div>Total Payouts</div>
              <div class="text-red-400 space-x-3 text-center text-xl">
                <div>{{ animateTotalPayouts.toLocaleString() }}</div>
              </div>
            </div>
          </div>
          <div class="lg:block flex justify-center">
            <div
              class="bg-darkbg space-y-2 text-base rounded-lg p-3 w-56 text-center"
            >
              <div>Total Profit</div>
              <div class="text-green-400 space-x-3 text-center text-xl">
                <div>
                  <p
                    :class="{
                      'text-redo': animateTotalProfit < 0,
                      'text-green-500': animateTotalProfit >= 0,
                    }"
                  >
                    {{ animateTotalProfit.toLocaleString() }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- {{ report }}/ -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { mapState } from "vuex";
export default {
  data() {
    return {
      newId: 0,
      res: [],
      totalGrossIncome: 0,
      animateTotalGrossIncome: (0).toFixed(0),
      totalPayouts: 0,
      animateTotalPayouts: (0).toFixed(0),
      totalTickets: 0,
      animateTotalTickets: (0).toFixed(0),
      totalProfit: 0,
      animateTotalProfit: (0).toFixed(0),
      totalEmployees: 0,
      animateTotalEmployees: (0).toFixed(0),
      options: {
        theme: {
          mode: "dark",
          palette: "palette10",
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: ["0", "ticket Sale", "Total income", "Payout", "Profit"],
        },
      },
      series: [
        {
          data: [],
        },
      ],
    };
  },
  props: {
    id: {
      required: true,
    },
  },
  created() {
    // console.log("hdegeba");
    this.fetchBranch(this.id);
  },
  watch: {
    id(newValue) {
      this.fetchBranch(newValue);
    },
    totalGrossIncome(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalGrossIncome: newValue,
      });
    },
    totalPayouts(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalPayouts: newValue,
      });
    },
    totalTickets(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalTickets: newValue,
      });
    },
    totalProfit(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalProfit: newValue,
      });
    },
    totalEmployees(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalEmployees: newValue,
      });
    },
  },
  computed: {
    ...mapState("branches", ["BranchesForDetail"]),
  },
  methods: {
    async fetchBranch() {
      // console.log("id", this.id);
      if (this.$route.query.page) {
        this.$router.push({ query: {} });
      }
      try {
        const id = await localStorage.getItem("activeAgent");
        // console.log("id", this.id);
        const res = await this.$store.dispatch("branches/getBranchById", id);
        // console.log("res: ", res);
        this.res = res;
        this.totalPayouts = res.unreportedTotalPayoutMoney;
        this.totalTickets = res.unreportedTotalTickets;
        this.totalGrossIncome = res.unreportedTotalPlacedMoney;
        this.totalProfit = res.unreportedTotalProfit;
        this.totalEmployees = this.BranchesForDetail.allBranches;
        if (res) {
          this.series = [
            {
              data: [
                "0",
                this.totalTickets,
                this.totalGrossIncome,
                this.totalPayouts,
                this.totalProfit,
              ],
            },
          ];
        }
      } catch (error) {
        // console.log("er: ", error);
      }
    },
    assignData() {
      if (this.BranchesForDetail) {
        this.totalPayouts = this.res.unreportedTotalPayoutMoney;
        // console.log("totalPayouts", this.totalPayouts);
        this.totalTickets = this.res.unreportedTotalTickets;
        this.totalGrossIncome = this.res.unreportedTotalPlacedMoney;
        this.totalProfit = this.res.unreportedTotalProfit;
        this.totalEmployees = this.BranchesForDetail.allBranches;
        this.series = [
          {
            data: [
              "0",
              this.totalTickets,
              this.totalGrossIncome,
              this.totalPayouts,
              this.totalProfit,
            ],
          },
        ];
      }
    },
  },
};
</script>

<style></style>
