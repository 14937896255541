<template>
  <div>
    <div class="flex justify-center py-4" v-if="noConnection">
      <network-issue />
    </div>
    <div v-if="!noConnection" class="bg-darkcard text-gray-300 rounded-md p-2">
      <BaseModal
        modalClass="md:w-3/4 w-11/12"
        :visible="gameDetailsStatus"
        callBack="fetchTickets"
      >
        <GameRecall class="w-full" :info="modalData"
      /></BaseModal>
      <div class="hidden lg:grid grid-cols-7 text-base">
        <div class="col-span-4">Tickets</div>
        <div class="flex space-x-3 col-span-3">
          <!-- <div class="dropdown hover:block relative inline-block my-auto mx-4">
            <p @mouseover="calendar = true">
              {{ selectedDate | date }}
            </p>
            <div
              class="dropdown-content hover:block hover:text-left hover:right-0 z-10 :hover hidden shadow-md px-3 py-4 absolute min-w-40 w-40 space-y-5 mr-5"
            >
              <date-picker
                v-if="calendar"
                @input="fetchTicketsByDate()"
                :isDark="true"
                v-model="selectedDate"
              ></date-picker>
            </div>
          </div> -->
          <!-- <p @mouseover="calendar = true" @mouseleave="calendar = false">
            {{ selectedDate | date }}
          </p>
          <date-picker v-if="calendar" v-model="selectedDate"></date-picker> -->
          <p class="text-base">Show</p>
          <div class="inline-block relative w-16">
            <BaseSelect
              class="space-x-3"
              @input="fetchTicketsWithSameId(id)"
              v-model="show"
              selectClass="w-full p-1 bg-darkbg text-base"
              optionClass="w-full space-x-3 font-thin text-lg"
              :options="returnShowLength"
            />
          </div>
          <p class="fa fa-filter text-yellow-500 fa-2x"></p>
          <div class="inline-block relative w-1/5">
            <select
              @change="filterTickets($event)"
              v-model="filter"
              class="bg-darkbg outline-none w-full p-1 rounded"
              style=""
            >
              <option class="bg-darkcard rounded-none" value="all">All</option>
              <option class="bg-darkcard rounded-none" value="won">Won</option>
              <option class="bg-darkcard rounded-none" value="lost">
                Lost
              </option>
              <option class="bg-darkcard rounded-none" value="pending">
                Pending
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <!-- <i class="fa fa-angle-down"></i> -->
            </div>
          </div>
          <!-- <div class="fa fa-filter text-yellow-500"></div> -->
          <BaseInput
            @keypress.enter="searchTicket()"
            autocomplete="off"
            placeholder="search ticket"
            type="Text"
            inputClass="px-2 bg-darkbg outline-none w-full p-1 rounded "
            v-model="search"
          />
          <BaseButton
            v-if="search"
            class="text-red-600"
            @click="getAllAfterSearch()"
            >cancel</BaseButton
          >
        </div>
      </div>
      <!-- <BaseDropDown /> -->
      <div class="lg:hidden text-base">
        <div class="flex justify-between">
          <div class="col-span-4">Tickets</div>
          <div class="flex">
            <BaseInput
              @keypress.enter="searchTicket()"
              autocomplete="off"
              placeholder="search ticket"
              type="Text"
              inputClass="px-2 bg-darkbg outline-none w-full p-1 rounded "
              v-model="search"
            />
            <BaseButton
              v-if="search"
              class="text-red-600 mt-2 fa fa-times"
              @click="getAllAfterSearch()"
            ></BaseButton>
          </div>
        </div>

        <div class="flex space-x-3 w-full"></div>
      </div>
      <div class="bg-darkbg h-full my-2 rounded-xl overflow-x-auto">
        <!-- <BaseTable
          :items="arrangeBodyItems"
          :headers="arrangeTableHeader"
          :contents="tickets"
        /> -->
        <table class="table-auto w-full text-sm">
          <thead class="sticky">
            <tr class="space-x-8 text-left font-light">
              <th class="border-b border-b-gray-700 px-4 font-light py-2">#</th>
              <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                BetID
              </th>
              <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                Game Id
              </th>
              <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                Cashier
              </th>
              <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                Bet Money
              </th>
              <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                Total Odds
              </th>

              <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                Possible Payout
              </th>

              <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                Prize Money
              </th>
              <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                Payout
              </th>
              <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                More
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-if="tickets.length !== 0"
              v-for="(tick, index) in tickets"
              :key="index"
              class="text-gray-400"
            >
              <td class="px-4 py-2">{{ index + 1 }}</td>
              <td class="px-4 py-2">{{ tick.customId }}</td>
              <td class="px-4 py-2">
                <p v-if="tick.gameId">
                  {{ tick.gameId.customId }}
                </p>
                <p v-if="!tick.gameId">---</p>
              </td>
              <td class="px-4 py-2">
                <p v-if="tick.placedCashier">
                  {{ tick.placedCashier.employeeName }}
                </p>
                <p v-if="!tick.placedCashier">---</p>
              </td>

              <td class="px-4 py-2">
                {{ tick.totalPlacedMoney.toLocaleString() }} birr
              </td>
              <td class="px-4 py-2">{{ tick.totalOdd }}</td>
              <td class="px-4 py-2">
                {{ tick.maxPossiblePayout.toLocaleString() }} birr
              </td>

              <td class="px-4 py-2 text-green-500">
                <p v-if="tick.totalPrizeMoney" class="text-red-500">
                  {{ tick.totalPrizeMoney.toLocaleString() }} birr
                </p>
                <p class="text-green-500" v-if="tick.totalPrizeMoney == 0">0</p>
              </td>
              <td class="px-4 text-right flex space-x-2 py-2">
                <img
                  v-if="tick.tookPrizeMoney == true"
                  class="w-4 h-4"
                  src="../../assets/icons/paidout.svg"
                  alt=""
                  srcset=""
                />
                <p v-if="tick.tookPrizeMoney == true">Yes</p>
                <p v-if="tick.tookPrizeMoney == false">No</p>
              </td>
              <td class="px-4 py-2" @click="handelDetails(tick)">More</td>
            </tr>
          </tbody>
        </table>
        <!-- {{ tableBodyHeight }} -->
        <!-- <div v-if="spinner2">
          <div class="flex justify-center py-16">
            <spinner2 class="flex justify-center" />
          </div>
        </div> -->
        <div
          :class="`h-${tableBodyHeight}`"
          :style="`height:${tableBodyHeight}`"
          class="text-3xl py-24 w-full text-center"
          v-if="tickets.length === 0 && !tickets == null"
        >
          There are no tickets available!
        </div>
      </div>

      <div v-if="allTickets > 13 && tickets.length !== 0" class="text-xs">
        <base-pagination :itemsCount="allTickets" />
        <!-- <div class="flex justify-start">
          <div>
            <template v-if="page !== 1">
              <div
                class="cursor-pointer text-base w-16"
                @click="paginate(page - 1)"
                rel="prev"
              >
                ← Prev |
              </div>
            </template>
          </div>

          <div class="lg:grid lg:grid-cols-30 grid grid-cols-8">
            <div
              class="grid grid-cols-12 text-sm cursor-pointer"
              @click="paginate(currentPage)"
              v-for="(currentPage, i) in paginationLength"
              :key="i"
              :class="{
                'bg-5  rounded-md text-white text-center h-6':
                  page == currentPage,
              }"
            >
              <p class="px-3">{{ currentPage }}</p>
            </div>
          </div>
          <div>
            <template v-if="page < paginationLength.length">
              <div
                @click="paginate(page + 1)"
                rel="prev"
                class="cursor-pointer text-base w-16"
              >
                | Next →
              </div>
            </template>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import GameRecall from "@/components/Modal/GameRecall.vue";
// import spinner from "@/components/spinner.vue";
// import spinner2 from "@/components/spinner2.vue";
import NetworkIssue from "@/Error/NetworkIssue.vue";
export default {
  components: {
    // spinner,
    NetworkIssue,
    GameRecall,
    // spinner2,
  },
  data() {
    return {
      modalData: [],
      selectedDate: "",
      filter: "all",
      calendar: false,
      // spinner2: false,
      noConnection: false,
      // spinner: false,
      search: "",
      tickets: [],
      watchedTickets: [],
      tableBodyHeight: 0,
      allTickets: 0,
      show: 13,
    };
  },
  props: {
    id: {
      type: String,
    },
  },
  watch: {
    id(newValue) {
      this.fetchTickets(newValue);
    },
  },
  created() {
    const length = localStorage.getItem("show");
    if (length) {
      this.show = length;
    } else {
      this.show = 13;
      localStorage.setItem("show", 13);
    }
    // this.spinner2 = true;
    this.selectedDate = new Date();
    this.fetchTickets(this.id);
    // this.spinner = true;
    // this.$store.dispatch("cashier/getCountAllTickets");
  },
  mounted() {
    // this.calculate();
  },

  computed: {
    ...mapGetters("modals", ["gameDetailsStatus"]),
    ...mapState("branches", ["progressStatus"]),

    returnShowLength() {
      let length = [10, 12, 13, 14, 16, 18, 20];
      return length;
    },
    paginationLength() {
      let page = [];
      let pages = this.allTickets / Number(this.show);
      for (var i = 1; i < pages + 1; i++) {
        page.push(i);
      }
      return page;
    },
    arrangeTableHeader() {
      const headers = [
        "Bet ID",
        "Game Id",
        "Cashier",
        "Bet Money",
        "Total Odds",
        "Possible Payout",
        "Prize Money",
        "Payout",
      ];
      return headers;
    },
    arrangeBodyItems() {
      const items = [
        { path: "customId" },
        { path: "gameId.customId" },
        { path: "placedCashier.employeeName" },
        { path: "totalPlacedMoney" },
        { path: "totalOdd" },
        { path: "maxPossiblePayout" },
        { path: "totalPrizeMoney", class: "text-redo : " },
        { path: "tookPrizeMoney" },
      ];
      return items;
    },
    //   restructureTableContent() {
    // this.tickets
    //   },
    page() {
      return parseInt(this.$route.query.page) || 1;
    },
  },
  methods: {
    async fetchTickets() {
      try {
        const id = await localStorage.getItem("activeAgent");
        // this.spinner2 = true;
        if (this.$route.query.page) {
          this.$router.push({ query: {} });
        }
        await this.$store
          .dispatch("branches/getBranchTicketById", {
            perPage: this.show,
            page: this.page,
            id,
          })
          .then((response) => {
            // console.log("responseDD:", response);
            if (response) {
              this.$store
                .dispatch("branches/countTicketOfBranches")
                .then((res) => {
                  if (res) {
                    this.allTickets = res.data.result;
                    // console.log("res: ", res);
                    // this.spinner = false;
                    this.noConnection = false;
                    this.tickets = response.doc;
                    // this.spinner2 = false;
                  } else {
                    // this.spinner = false;
                    this.noConnection = true;
                  }
                });
            } else {
              // this.spinner = false;
              this.noConnection = true;
            }
          });
      } catch (error) {
        this.noConnection = true;
      }
    },
    fetchTicketsWithSameId(id) {
      localStorage.setItem("show", this.show);
      this.$store
        .dispatch("branches/getBranchTicketById", {
          perPage: this.show,
          page: this.page,
          id,
        })
        .then((response) => {
          if (response) {
            this.tickets = response.doc;
            this.noConnection = false;
          } else {
            this.noConnection = true;
          }
        })
        .catch((err) => {
          // this.spinner = false;
          this.noConnection = true;
        });
    },
    handelDetails(content) {
      // console.log("conte:", content);
      this.modalData = content;
      this.modal();
    },
    filterTickets(event) {
      // console.log(event.target.value);
      // if (event.target.value == "won") {
      //   this.tickets = this.tickets.filter((money) => {
      //     return Number(money.totalPrizeMoney) > 0;
      //   });
      //   console.log("after filter : ", this.tickets);
      // } else if (event.target.value == "lost") {
      //   this.tickets = this.tickets.filter((money) => {
      //     return Number(money.totalPrizeMoney) == 0;
      //   });
      //   console.log("after filter : ", this.tickets);
      // } else if (event.target.value == "Pending") {
      //   this.tickets = this.tickets.filter((status) => {
      //     return Number(status.gameId.status) == "pending";
      //   });
      //   console.log("after filter : ", this.tickets);
      // }
    },
    fetchTicketsByDate() {
      this.calendar = false;
    },
    paginate(selectedPage) {
      // this.spinner2 = true;
      this.$router.push({ query: { page: selectedPage } });
      this.$store
        .dispatch("branches/getBranchTicketById", {
          id: this.id,
          perPage: this.show,
          page: this.page,
        })
        .then((response) => {
          if (response) {
            this.tickets = response.doc;
            // this.spinner2 = false;
          } else {
            // this.spinner2 = false;
            this.networkIssue = true;
          }
        });
    },
    pushToTicketDetails(tick) {
      // console.log("tick: ", tick);
      this.$router.push({
        name: "ticket-details",
        params: { id: tick.customId },
      });
      // console.log("After: ");
    },
    searchTicket() {
      this.$store
        .dispatch("branches/searchTicket", this.search)
        .then((response) => {
          if (response) {
            // console.log("response: ", response);
            this.tickets = response;
          }
        });
    },
    getAllAfterSearch() {
      this.search = "";
      this.$store
        .dispatch("cashier/getAllTickets", {
          perPage: this.show,
          page: this.page,
        })
        .then((response) => {
          if (response) {
            this.watchedTickets = response;
          }
        });
    },
    modal() {
      this.$store.dispatch("modals/modal", {
        stat: "gameDetails",
        status: !this.gameDetailsStatus,
      });
    },
  },
};
</script>

<style>
/* .dropdown:hover .dropdown-content {
  display: block;
  right: 0%;
  text-align: left;
} */
.custom-dropdown {
  position: relative;
  display: inline-block;
}

.custom-dropdown__selected {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0.5rem;
  cursor: pointer;
}

.custom-dropdown__options {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}

.custom-dropdown__option {
  padding: 0.5rem;
  cursor: pointer;
}

.custom-dropdown.open .custom-dropdown__options {
  display: block;
}
</style>
