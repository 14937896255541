<template>
  <div id="Myfont" class="h-screen">
    <div class="flex justify-center">
      <div
        class="my-12 py-10 bg-darkcard shadow-xs p-3"
        style="border-radius: 5px"
      >
        <div class="p-2">
          <div class="flex justify-center">
            <img class="h-20" src="../../assets/Login.svg" alt="" />
          </div>

          <div class="m-4 flex justify-center space-y-2">
            <p class="text-2xl text-blue-700">Reset Password</p>
            <!-- <img src="../../p/Lock screen.svg" class="h-24 w-24 justify-start" alt="" /> -->
          </div>

          <form
            @submit.prevent="onSubmit"
            style="width: 350px"
            class="mt-2 text-white space-y-3 max-w-2xl"
          >
            <div>
              <BaseInput
                autocomplete="off"
                label="Code"
                inputClass="bg-darkbg outline-none w-full p-2 rounded "
                v-model.trim="formdata.resetCode"
                type="number"
                @blur="$v.formdata.resetCode.$touch()"
              />
              <div v-if="$v.formdata.resetCode.$error">
                <p
                  class="text-xs text-red-500"
                  v-if="!$v.formdata.resetCode.required"
                  id="error_label"
                >
                  Reset Code is required!
                </p>
              </div>
              <div v-if="$v.formdata.resetCode.$error">
                <p
                  class="text-xs text-red-600"
                  v-if="!$v.formdata.resetCode.minLength"
                  id="error_label"
                >
                  code must be 6 digits!
                </p>
                <p
                  class="text-xs text-red-600"
                  v-if="!$v.formdata.resetCode.maxLength"
                  id="error_label"
                >
                  code must be 6 digits!
                </p>
              </div>
            </div>
            <!-- <div>
              <BaseInput
                autocomplete="off"
                label="New Password"
                type="Password"
                inputClass="bg-darkbg outline-none w-full p-2 rounded "
                v-model.trim="formdata.password"
                @blur="$v.formdata.password.$touch()"
              />

              <div v-if="$v.formdata.password.$error">
                <p
                  class="text-xs text-red-500"
                  v-if="!$v.formdata.password.required"
                  id="error_label"
                >
                  password is required!
                </p>
              </div>
              <div v-if="$v.formdata.password.$error">
                <p
                  class="text-xs text-red-600"
                  v-if="!$v.formdata.password.minLength"
                  id="error_label"
                >
                  Password must be greater than 6 characters!
                </p>
                <p
                  class="text-xs text-red-600"
                  v-if="!$v.formdata.password.maxLength"
                  id="error_label"
                >
                  Password must be less than 12 characters!
                </p>
              </div>
            </div> -->
            <div class="relative">
              <BaseInput
                autocomplete="off"
                label="New Password"
                type="Password"
                :type="show ? 'text' : 'password'"
                inputClass="bg-darkbg outline-none w-full p-2 rounded "
                v-model.trim="formdata.password"
                @blur="$v.formdata.password.$touch()"
              />
              <div v-if="$v.formdata.password.$error">
                <p
                  class="text-xs text-red-500"
                  v-if="!$v.formdata.password.required"
                  id="error_label"
                >
                  password is required!
                </p>
              </div>
              <div v-if="$v.formdata.password.$error">
                <p
                  class="text-xs text-red-600"
                  v-if="!$v.formdata.password.minLength"
                  id="error_label"
                >
                  Password must be greater than 6 characters!
                </p>
                <p
                  class="text-xs text-red-600"
                  v-if="!$v.formdata.password.maxLength"
                  id="error_label"
                >
                  Password must be less than 12 characters!
                </p>
              </div>

              <span
                :class="{
                  hidden: show,
                  block: !show,
                  'mt-6': !$v.formdata.password.$error,
                }"
                class="absolute inset-y-0 left-72 ml-2 grid place-content-center px-4"
              >
                <svg
                  @click="show = !show"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              </span>

              <span
                :class="{
                  block: show,
                  hidden: !show,
                  'mt-6': !$v.formdata.password.$error,
                }"
                class="absolute inset-y-0 left-72 ml-2 grid place-content-center px-4"
              >
                <img
                  @click="show = !show"
                  class="text-1 h-8 w-8"
                  src="../../assets/eyeslashsvgrepocom.svg"
                  alt=""
                  srcset=""
                />
              </span>
            </div>
            <div class="relative">
              <BaseInput
                autocomplete="off"
                label="Confirm Password"
                type="Password"
                :type="showConfirm ? 'text' : 'password'"
                inputClass="bg-darkbg outline-none w-full p-2 rounded "
                v-model.trim="formdata.passwordConfirm"
                @blur="$v.formdata.passwordConfirm.$touch()"
              />

              <span
                :class="{
                  hidden: showConfirm,
                  block: !showConfirm,
                  'mt-6': !$v.formdata.passwordConfirm.$error,
                }"
                class="absolute inset-y-0 left-72 ml-2 grid place-content-center px-4"
              >
                <svg
                  @click="showConfirm = !showConfirm"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              </span>

              <span
                :class="{
                  block: showConfirm,
                  hidden: !showConfirm,

                  'mt-6': !$v.formdata.passwordConfirm.$error,
                }"
                class="absolute inset-y-0 left-72 ml-2 grid place-content-center px-4"
              >
                <img
                  @click="showConfirm = !showConfirm"
                  class="text-1 h-8 w-8"
                  src="../../assets/eyeslashsvgrepocom.svg"
                  alt=""
                  srcset=""
                />
              </span>
              <div v-if="$v.formdata.passwordConfirm.$error">
                <p
                  class="text-xs text-red-500"
                  v-if="!$v.formdata.passwordConfirm.sameAs"
                  id="error_label"
                >
                  Passwords don't match!
                </p>
              </div>
            </div>
            <!-- <div>
              <BaseInput
                autocomplete="off"
                class=""
                type="password"
                inputClass="bg-darkbg outline-none w-full p-2 rounded "
                label="Confirm Password"
                v-model.trim="formdata.passwordConfirm"
                @blur="$v.formdata.passwordConfirm.$touch()"
              />
              <div v-if="$v.formdata.passwordConfirm.$error">
                <p
                  class="text-xs text-red-500"
                  v-if="!$v.formdata.passwordConfirm.sameAs"
                  id="error_label"
                >
                  Passwords don't match!
                </p>
              </div>
            </div> -->
          </form>
          <div class="flex justify-center">
            <button
              :disabled="forgetPasswordRequestSent"
              type="submit"
              Class="bg-text flex space-x-3 mt-3 justify-center py-1 w-full text-white"
              @click="onSubmit()"
            >
              <svg
                v-if="forgetPasswordRequestSent"
                class="h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Submit
            </button>
          </div>
          <p v-if="error" class="text-xs font-semibold text-red-700">
            please fill out the forms correctly!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  maxLength,
  minLength,
  sameAs,
  between,
} from "vuelidate/lib/validators";
export default {
  components: {},
  data() {
    return {
      show: false,
      showConfirm: false,
      error: false,
      status: false,
      formdata: {
        email: "",
        resetCode: "",
        password: "",
        passwordConfirm: "",
      },
      forgetPasswordRequestSent: false,
    };
  },

  validations: {
    formdata: {
      resetCode: { required, minLength: minLength(6), maxLength: maxLength(6) },
      passwordConfirm: { required, passwordConfirm: sameAs("password") },
      password: { required, minLength: minLength(8), maxLength: maxLength(12) },
      // password: { required },
      // code: { required, numeric },
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.formdata.email = localStorage.getItem("email");
        this.forgetPasswordRequestSent = true;
        this.$store
          .dispatch("Authentication/ResetPassword", this.formdata)
          .then((response) => {
            // console.log("response", response);
            if (response && !response.error) {
              this.$router.push({
                name: "dashboard",
              });
            } else {
              this.forgetPasswordRequestSent = false;
            }
          });
      } else {
        // console.log("error");
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 5000);
      }
    },
    login() {
      this.$store
        .dispatch("Authentication/signIn", this.formdata)
        .then((response) => {
          if (response) {
            this.$router.push({
              name: "dashboard",
            });
          }
        });
    },
  },
};
</script>

<style scoped>
#bg {
  /* background-image: url("../../assets/Players/s64.jpg");
  background-image: url("../../assets/Players/s64.jpg"); */
  background-position: px;
  background-size: 380px;
  background-position-x: 450px;
  /* background-repeat: ; */
}
</style>
